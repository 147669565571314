export interface IClient {
    name: string,
    last_name:string,
    dateOperation?:string,
    email:string,
    phone_number:string,
    state_province:string,
    country:string,
    city:string,
    arrive_type:string,
    terms_conditions:boolean,
    point_sale_id:number
}
export interface IClientState{
    client:IClient,
    folio:string,
    card_token?:string,
    device_session_id?:string,
    id_payment?:string,
    date_operation:string,
    loading:boolean,
    is_valid:boolean
}
export interface IClientGet{
    idReserva_web:number
    intHotel_Barco:string
    strNombre:string
    strApellido:string,
    dateOperation?:string
    strEmail:string
    strTelefono:string
    strEstado:string
    strPais:string
    strCiudad:string
    typePointSale:string,
}
export let InitialClient:IClient={
    name: '',
    last_name:'',
    email:'',
    phone_number:'',
    state_province:'',
    country:'',
    city:'',
    arrive_type:'',
    terms_conditions:false,
    point_sale_id:0
}
