import React, {useEffect,useRef} from 'react';
import {Modal} from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {ParasailVertical} from "../../assets/images/index";
import InputLabel  from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import {useSelector} from "react-redux";
import {AppSelector, changeUrlFirst, close_modal_addon, open_modal_addon} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {changePax, listAddons, packageSelector} from "../../store/packages/package";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {clientSelector, fillFolioDate, getClientInfo} from "../../store/clientInfo/clientInfo";
import {IAddons} from "../../models/package";
import {closeBackdrop, openBackdrop} from "../../store/app/app";
import {unwrapResult} from '@reduxjs/toolkit';
import {addItem, cartSelector, changeCurrency,cartResumeSelector} from "../../store/cart/cart";
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
export const ModalAddon = () => {
    const appSelector = useSelector(AppSelector);

    const dispatch = useAppDispatch();
    const {modal_addon} = appSelector;
    const PackageSelector = useSelector(packageSelector);
    const ClientSelector = useSelector(clientSelector);
    const CartSelectorResume = useSelector(cartResumeSelector);
    let {
        resume_currency,
    } = CartSelectorResume;
    let {
        folio
    } = ClientSelector;
    let {
        addons,
        loading,
        sku_addon
    } = PackageSelector;
    const CartSelector = useSelector(cartSelector);
    let {
        cookie,
    } = CartSelector;
    useEffect(() => {
        if(addons.length<1){
            dispatch(listAddons({}))
        }

    }, []);

    let skuAddonRef = useRef(sku_addon);
    let folioRef=useRef(folio);
    return        <Modal
        className="modalAddons"
        open={modal_addon}
        disableAutoFocus
        disableEscapeKeyDown
        disableRestoreFocus
        disableEnforceFocus
        disableScrollLock
        // onClose={()=>dispatch(close_modal_addon())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div className="contModal">
            <div className="imgAddon">
                <img src={ParasailVertical} alt="imgAddons"/>
            </div>
            <div className="formAddon">
                <Typography variant="body1" gutterBottom style={{fontSize:'13px'}}>Please be sure to enter your booking number in the box Reservation Number. Your booking number is issued in your e-ticket at the time of purchasing a tour with us. In order to be able to purchase an add-on experience you will need to have purchased a tour previously
                    Thank you!!</Typography>

                <div className="mt-20">
                    <InputLabel id="label" style={{fontSize:'12px'}}>Select Service</InputLabel>
                    {
                        loading?<>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        </>: <Select
                                defaultValue={sku_addon}
                                labelId="label"
                                id="select"
                                inputRef={skuAddonRef}
                                variant="standard"
                                style={{width:'165px'}}>
                            {

                                addons.map((addon: IAddons,index:number)=>{
                                    return <MenuItem key={addon.sku} value={addon.sku}>{addon.tour}</MenuItem>
                                })
                            }
                        </Select>
                    }

                    <TextField
                        id="standard-basic"
                        inputRef={folioRef}
                        label="Reservation Number"
                        defaultValue={folio}
                        variant="standard"
                        className="mt-20"/>

                </div>
                <div style={{display:'flex',justifyContent:'center',alignItems:"end",width:'150px',margin:'0 auto'}}>
                    <Button
                        variant="outlined"
                        onClick={()=>{
                            if(folioRef.current.value!=='' && skuAddonRef.current.value!==''){
                                dispatch(openBackdrop())
                                dispatch(getClientInfo({folio:folioRef.current.value??''})).then(unwrapResult).then((result: any) => {
                                    dispatch(fillFolioDate({folio:folioRef.current.value??'',date_operation:result?.dateOperation}))
                                    dispatch(changeCurrency(result?.currency))
                                    dispatch(changeUrlFirst(`id=${folioRef.current.value??''}&date_operation=${result?.dateOperation??''}&adult=${1}&children=${0}&currency=${'MXN'}`));
                                    dispatch(close_modal_addon())
                                    dispatch(addItem({package:{
                                            date_operation:result?.dateOperation,
                                            adult:1,
                                            currency:resume_currency,
                                            children:0,
                                        },
                                        currency:resume_currency,
                                        id:skuAddonRef.current.value,
                                        cookie:cookie})).then(unwrapResult).then((resultAdd: any) => {
                                        dispatch(changePax({pax:0,index:0}))
                                        dispatch(closeBackdrop())
                                        dispatch(openNotifier({variant: "success", open: true, message: `package is added successfully`}))
                                    }).catch((result: AxiosError) => {

                                        // @ts-ignore
                                        dispatch(openNotifier({variant: "error", open: true, message: `an error occurred while trying to add it`}))
                                    })
                                }).catch((result: AxiosError) => {
                                    // @ts-ignore
                                    dispatch(openNotifier({variant: "error", open: true, message: `an error occurred with the reservation number,try with a valid one`}))
                                })


                            }
                        }}
                        type="button"
                        className="btnCupon"
                        style={{width:'150px !important'}}
                    >Agregar</Button>
                </div>
            </div>
        </div>
    </Modal>;
};


