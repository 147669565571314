import React from 'react';
import {useSelector} from 'react-redux';
import {ErrorDateSelector,open_modal_error,close_modal_error} from "../../store/app/app";
import {
    cartSelector,
    removeItem,
} from '../../store/cart/cart';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {namespaces} from "../../i18n/i18n.constants";
import {useAppDispatch} from "../../store/store";
import {useTranslation} from "react-i18next";
import {
    Error
} from "../../assets/images/index";
interface IDialog {
    title:string,
    id:string
}
export const ErrorDate = () => {
    const appSelector = useSelector(ErrorDateSelector);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const CartSelector = useSelector(cartSelector)
    let {
        cookie,
    } = CartSelector;
    const {open_error_date,origin_sale} = appSelector;

    return      <Dialog
        open={open_error_date}
        onClose={close_modal_error}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div><img src={Error} width='100%'/></div>
        <DialogTitle id="alert-dialog-title">
            {t('something_wrong'??'',{ ns: namespaces.header})}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t('date_choose'??'',{ ns: namespaces.header})}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="btnAceptTrash" onClick={()=>{
                window.location.href =
                    origin_sale === '1' ? "https://www.playamia.mx" :
                        origin_sale === '2' ? "https://app.playamia.mx" :
                            origin_sale === '3' ? "https://app.playamia.com" :
                                origin_sale === '4' ? "https://playamia.com" :
                                    origin_sale === '7' ? "https://playamia.com.mx" :
                                        origin_sale === '8' ? "https://app.playamia.com.mx" :
                                            // (puedes agregar un valor por defecto aquí si lo deseas)
                                            "https://playamia.com";
            }}>
                {t('acepp'??'',{ ns: namespaces.header})}
            </Button>
        </DialogActions>
    </Dialog>
};

export default ErrorDate;
