import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import {IPackage,ICostumer,IReservation} from '../../types/cart/cart'
import {IClient, IClientState,IClientGet} from "../../models/client";
import {IHotelCruise} from "../../models/hotelCruiseLine";
import {closeBackdrop, openBackdrop} from "../../store/app/app";
const api = process.env.REACT_APP_API_URL
const initialState: IClientState = {
    client: {
        name: '',
        last_name: '',
        email: '',
        phone_number: '',
        state_province: '',
        country: '',
        city: '',
        dateOperation:undefined,
        arrive_type: '',
        point_sale_id: 0,
        terms_conditions:false,
    },
    folio:'',
    device_session_id:'',
    id_payment:'',
    card_token:'',
    date_operation:'',
    loading: false,
    is_valid:false
}

export const getClientInfo = createAsyncThunk<IClientGet,
    {folio:string },
    {
        rejectValue: unknown
    }>(
    'package/getClientInfo',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/checkout/showClient/${data.folio}`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)

export const clientSlice = createSlice({
    initialState,
    name: 'client',
    extraReducers: builder => {
        builder
            .addCase(getClientInfo.pending, state => {
                state.loading = true
            })
            .addCase(getClientInfo.fulfilled, (state, {payload}) => {

                state.client.name=payload.strNombre
                state.client.last_name=payload.strApellido
                state.client.email=payload.strEmail
                state.client.phone_number=payload.strTelefono
                state.client.dateOperation=payload.dateOperation
                state.client.state_province=payload.strEstado
                state.client.country=payload.strPais
                state.client.city=payload.strCiudad
                state.client.arrive_type=payload.typePointSale
                state.client.terms_conditions=true
                state.client.point_sale_id=(+payload.intHotel_Barco)
                state.loading = false
            })
            .addCase(getClientInfo.rejected, (state, action) => {
                state.loading = true
            })
    },
    reducers: {
        fillClient: (state, action: PayloadAction<IClient>) => {
            state.client = {...action.payload};
        },
        fillFolioDate: (state, action: PayloadAction<{folio:string,date_operation:string}>) => {
            state.date_operation = action.payload.date_operation
            state.folio = action.payload.folio
        },
        fillFolio:(state, action: PayloadAction<{folio:string}>)=>{
            state.folio=action.payload.folio
        },
        fillDate:(state, action: PayloadAction<{date:string}>)=>{
            state.date_operation=action.payload.date
        },
        resetClient:(state)=>{
            state.client.name=initialState.client.name
            state.client.last_name=initialState.client.last_name
            state.client.email=initialState.client.email
            state.client.phone_number=initialState.client.phone_number
            state.client.state_province=initialState.client.state_province
            state.client.country=initialState.client.country
            state.client.city=initialState.client.city
            state.client.dateOperation=initialState.client.dateOperation
            state.client.arrive_type=initialState.client.arrive_type
            state.client.point_sale_id=initialState.client.point_sale_id
            state.client.terms_conditions=initialState.client.terms_conditions
        },
        fillIdPayment:(state, action: PayloadAction<{idPayment:string}>)=>{
            state.id_payment=action.payload.idPayment
        },
        changeValidForm:(state)=>{
          state.is_valid=true
        },
        changeUnValidForm:(state)=>{
            state.is_valid=false
        },
        loading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
})

export const clientSelector = (state: RootState) => state.client;

export const {
    fillClient,
    fillFolioDate,
    resetClient,
    fillIdPayment,
    changeValidForm,
    changeUnValidForm,
    fillFolio,
    loading,
    fillDate
} = clientSlice.actions;
export const ClientReducer = persistReducer({
    storage: storageSession,
    key: 'client',
    // whitelist: ['access_token', 'user'],
    // blacklist: ['is_valid']
}, clientSlice.reducer)
