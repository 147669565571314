import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {PersistGate} from "redux-persist/integration/react";
import store,{persistor} from '../src/store/store'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
// import i18n (needs to be bundled ;))
import './i18n/i18n';
const clientID = process.env.REACT_APP_PAYPAL_CLIENT_SECRET_ID
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
        <Provider store={store} >
            <PayPalScriptProvider options={{ "client-id": clientID??'' }}>
                <SnackbarProvider
                    maxSnack={1}
                >
            <PersistGate persistor={persistor}>
                    <App  />
            </PersistGate>
                </SnackbarProvider>
            </PayPalScriptProvider>
        </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
