import React, {useEffect,useState} from 'react';
import {Card, CardContent,Divider} from "@mui/material";
import {
    DAS,
    BB1,
    SNK,
    Boy,
    Calendar,
    Man,
    Taxi,
    CGDP003,
    CGDP002,
    CGDP001,
    CGDSST010,
    CGMCST008,
    CGSKL007,
    CGCCBBST011,
    CDPMADS001,
    CDPMADS003,
    CDPMADS004,
    CDPMADS005,
    CDPMADS006,
    CDPMADS007,
    CDPMADS008,
    CDPMADS009, CDPMADS010, CDPMADS011, CDPMADS012, CDPMADS014, CDPMADS015,
    PMADS001,
    PMADS003,
    PMADS005,
    PMADS011,
    PMADS013,
    PMADS004,
    PMADS012,
    PMADS014,
    PMADS015,
    PMADS016, PMADS008
} from "../../assets/images/index";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {openDialog} from "../../store/app/app";
import {PackageIds} from "../../models/package";
import {
    editItem,
    cartSelector,
    addTransferItem
} from '../../store/cart/cart';
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";


interface ICardPackage{
    paragrahp_1:string
    paragrahp_2?:string
    paragrahp_3?:string
    date_reservation:string
    amount:number
    adult:number
    child:number
    id_tour:string,
    type:'services'|'add-ons',
    transfer:string |null
}



const NamePackage=({package_name}: {package_name: string})=>{
    let name = package_name
    name = name.replace('break', 'Break®');
    name = name.replace('Break', 'Break®');
    name = name.replace('Break®®', 'Break®');
    return name
}
interface image_type {
    [key: string]: any;
}
const GetImage=({id_tour}: {id_tour: string})=> {
    const images_tour:image_type = {
        //    CZM
        "PMCZM001": <img src={CGDP001} alt="beach break "/>,
        "PMCZM003": <img src={CGDP002} alt="All You Can Eat Beach Break"/>,
        "PMCZM004": <img src={CGDP003} alt="Amazing Beach Break"/>,
        "PMCZM001-TF01": <img src={CGDP001} alt="Beach Break Transfer"/>,
        "PMCZM003-TF01": <img src={CGDP002} alt="All You Can Eat Beach Break + Transfer"/>,
        "PMCZM004-TF01": <img src={CGDP003} alt="Amazing Beach Break Transfer"/>,
        "PMCZM008": <img src={CGDSST010} alt="Dance & Salsa"/>,
        "PMCZM008-TF01": <img src={CGDSST010} alt="Dance & Salsa Transfer"/>,
        "PMCZM009": <img src={SNK} alt="Cozumel Driving tour Snorkel -Jade Cavern & Beach Break"/>,
        "PMCZM006": <img src={CGMCST008} alt="Mexican Cuisine Workshop & Tasting"/>,
        "PMCZM006-TF01": <img src={CGMCST008} alt="Mexican Cuisine Workshop & Tasting Transfer"/>,
        "PMCZM005": <img src={CGSKL007} alt="Cozumel Snorkel Tour: Coral Reefs, el Cielo & Beach Break"/>,
        "PMCZM007": <img src={CGCCBBST011} alt="Cuban Cigar Master & Beach Break"/>,
        //    PDC
        "PMPDC001": <img src={CGSKL007} alt="Snorkel Tour: Coral Reefs, el Cielo & Beach (show up PDC)"/>,
        "PMPDC002": <img src={CGSKL007} alt="Snorkel Tour: Coral Reefs, el Cielo & Beach (Hotel Pick up)"/>,
        "PMPDC004": <img src={CGDP002} alt="Beach Break® Experience (Hotel Pick up)"/>,
        "PMPDC003": <img src={CGDP003} alt="Beach Break ® Experience (show up PDC)"/>,
        //    ADDONS
        "PMADS001": <img src={PMADS001} alt="Fish SPA"/>,
        "PMADS003": <img src={PMADS003} alt="Snorkel Tour Xtabay"/>,
        "PMADS005": <img src={PMADS005} alt="Wave Runner 30 MIN"/>,
        "PMADS006": <img src={PMADS005} alt="Wave Runner 1 HR"/>,
        "PMADS011": <img src={PMADS011} alt="Manicure Basico"/>,
        "PMADS013": <img src={PMADS013} alt="Camas Lounge"/>,
        "PMADS015": <img src={PMADS015} alt="Hobie Cat 1 Hr"/>,
        "PMADS008": <img src={PMADS008} alt="Masaje Relajante 60 min"/>,
        "PMADS009": <img src={PMADS008} alt="Masaje Reflexologia 30 min"/>,
        "PMADS004": <img src={PMADS004} alt="Snorkel Gear "/>,
    };

    return images_tour[id_tour] ?? <img src={CGDP001} alt="breach break 1"/>;
}
const getColor=({id_tour}: {id_tour: string})=> {
    const tour_background:image_type = {
        "PMCZM001": 'bg-azul',
        "PMCZM001-TF01": 'bg-azul',
        "PMCZM003": 'bg-azul',
        "PMCZM003-TF01": 'bg-azul',
        "PMCZM004": 'bg-azul',
        "PMCZM004-TF01": 'bg-azul',
        "PMPDC003": 'bg-azul',
        "PMPDC004": 'bg-azul',
        "PMCZM009": 'bg-morado',
        "PMCZM007": 'bg-morado',
        "PMCZM006": 'bg-morado',
        "PMCZM005": 'bg-rosado',
        "PMPDC001": 'bg-rosado',
        "PMPDC002": 'bg-rosado',
    };

    return tour_background[id_tour] ?? 'bg-azul';
}
const package_found_id=({id_tour}: {id_tour: string})=>{
    type ObjectKey = keyof typeof PackageIds;
    const valueOfId = id_tour as ObjectKey;
    return PackageIds[valueOfId]
}

export const CardPackage=({id_tour,paragrahp_1,paragrahp_2,paragrahp_3,date_reservation,adult,child,transfer,amount,type}:ICardPackage)=> {
    const dispatch = useAppDispatch();
    const [haveTransfer, setHaveTransfer] = useState(false);
    const CartSelector = useSelector(cartSelector);
    useEffect(() => {
        setHaveTransfer(!!package_found_id({id_tour: id_tour}))
    }, [id_tour]);
    let {
        cookie,
        cart: {
            resume: {
                currency
            }
        }
    } = CartSelector;
    const { t } = useTranslation();
    return (
        <Card sx={{display: 'flex', marginBottom:'15px',background:'#fff',boxShadow:'0px 2px 6px -1px rgb(0 0 0 / 20%)',borderRadius:'10px'}} className="CartItem">
            <div className={`contImg ${getColor({id_tour:id_tour})}`}>
                <div className="bg-after"></div>
                <GetImage id_tour={id_tour}></GetImage>
                <div className="cont-text-item">
                    <Typography variant="h4" className="sunValley titulo1">{
                        NamePackage({package_name:paragrahp_1})
                    }</Typography>
                    <Typography variant="h6" className="w-68"><span className="NunitoBold titulo2">{paragrahp_2}</span> <span
                        className="AshleyRegular titulo3">{paragrahp_3}</span></Typography>
                </div>
            </div>
                <CardContent sx={{flex: '1 0 auto'}} className="cardContent">
                        <Grid xs={6} className="Grid">
                            <div className="contGrid">
                                <div className="cont-grid">
                                    <img src={Calendar} alt="icon calendar" className="iconItem"/>
                                    <span> {t('date_of_reservation'??'',{ ns: namespaces.check_your_cart})}:{date_reservation}</span>
                                </div>
                                <div className="contResp">
                                    <div className="cont-grid pd-08">
                                        <div className="cont-txt-icon">
                                            <img src={Man} alt="icon man" className="iconItem"/>
                                            <span> {


                                                    id_tour === 'PMADS005' || id_tour === 'PMADS006'
                                                    ? t('water_m' ?? '', { ns: namespaces.modal_addon })
                                                    : id_tour === 'PMADS013'
                                                        ? t('beds' ?? '', { ns: namespaces.modal_addon })
                                                        : t('adults' ?? '', { ns: namespaces.modal_addon })


                                            } </span>
                                            <span> { adult}</span>
                                        </div>
                                        <div className="btnMasMenos">
                                            <IconButton color="primary" disabled={ adult<=1} onClick={()=>{
                                                dispatch(editItem({adult:adult-1,id:id_tour,cookie:cookie,children:child,date_operation:date_reservation,currency:currency}))
                                            }} aria-label="upload picture" component="label" className="btnCart">
                                                <span className="menosIcon"><RemoveIcon/></span>
                                            </IconButton>
                                            <IconButton color="primary" disabled={type==='add-ons'&& adult>=10} onClick={()=>{
                                                dispatch(editItem({adult:adult+1,id:id_tour,cookie:cookie,children:child,date_operation:date_reservation,currency:currency}))
                                            }} aria-label="upload picture" component="label" className="btnCart">
                                                <span className="addIcon"><AddIcon/></span>
                                            </IconButton>
                                        </div>
                                    </div>
                                    {
                                        type==='services' && <div className="cont-grid">
                                            <div className="cont-txt-icon">
                                                <img src={Boy} alt="icon boy" className="iconItem"/>
                                                <span>  {t('children'??'',{ ns: namespaces.check_your_cart})}:</span><span> { child}</span>
                                            </div>
                                            <div className="btnMasMenos">
                                                <IconButton color="primary"
                                                            disabled={ child<=0}
                                                            onClick={()=>{
                                                                dispatch(editItem({children:child-1,id:id_tour,cookie:cookie,adult:adult,date_operation:date_reservation,currency:currency}))
                                                            }}
                                                            aria-label="upload picture" component="label" className="btnCart">
                                                    <span className="menosIcon"><RemoveIcon/></span>
                                                </IconButton>
                                                <IconButton color="primary"
                                                            onClick={()=>{
                                                                dispatch(editItem({adult:adult,id:id_tour,cookie:cookie,children:child+1,date_operation:date_reservation,currency:currency}))
                                                            }}
                                                            aria-label="upload picture" component="label" className="btnCart">
                                                    <span className="addIcon"><AddIcon/></span>
                                                </IconButton>
                                            </div>
                                        </div>
                                    }

                                    {
                                        PackageIds.hasOwnProperty(id_tour)   &&   <div className="cont-grid">
                                            <div className="cont-txt-icon">
                                                <img src={Taxi} alt="icon taxi" className="iconItem"/>
                                                <span> {t('transportation'??'',{ ns: namespaces.check_your_cart})}:  {/*{transfer?'Incluido':'No Incluido'}*/}</span>
                                                <div>&nbsp;</div>
                                            </div>
                                            <div className="btnMasMenos">
                                                <IconButton color="primary"
                                                            onClick={()=>{
                                                                 if(transfer===null){
                                                                     dispatch(addTransferItem({
                                                                             package:{
                                                                                 date_operation:date_reservation,
                                                                                 adult:+adult,
                                                                                 children:+child,
                                                                                 currency:currency
                                                                             },id:id_tour,type_call:true,transfer:transfer!==null,
                                                                            date:date_reservation,
                                                                             cookie:cookie
                                                                         }
                                                                     ))
                                                                 }

                                                            }}
                                                            aria-label="upload picture" component="label" className="btnCart">
                                                    <span className={`yesIcon ${transfer ? "active-icon" : ""}`}>YES</span>
                                                </IconButton>
                                                <IconButton color="primary"
                                                            onClick={()=>{
                                                                if(transfer!==null){
                                                                    dispatch(addTransferItem({
                                                                            package:{
                                                                                date_operation:date_reservation,
                                                                                adult:+adult,
                                                                                children:+child,
                                                                                currency:currency
                                                                            },id:id_tour,type_call:false,
                                                                            date:date_reservation,
                                                                            transfer:transfer!==null,
                                                                            cookie:cookie
                                                                        }
                                                                    ))
                                                                }
                                                            }}
                                                            aria-label="upload picture" component="label" className="btnCart">
                                                    <span className={`noIcon ${transfer==null ? "active-icon" : ""}`}>NO</span>
                                                </IconButton>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={4} className="cont-precio">
                            <Divider orientation="vertical" variant="middle" flexItem sx={{height:'60%',margin:'auto'}}/>
                            <div className="w-100">
                                <Typography className="AshleyRegular">TOTAL</Typography>
                                <Typography className="NunitoBold txt-30">${amount}</Typography>
                                <Typography className="NunitoBold txt-16">{currency}</Typography>
                            </div>
                        </Grid>
                        <Grid xs={2} className="cont-btn-borrar" onClick={()=>{
                            dispatch(openDialog({tittle:paragrahp_1,id:id_tour,open:true,date:date_reservation}))
                        }}>
                            <div>
                                <DeleteOutlineIcon/>
                            </div>
                        </Grid>
                </CardContent >
        </Card>

    );
}

