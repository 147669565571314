import React from 'react';
import {useSelector} from 'react-redux';
import {DialogSelector,closeDialog} from "../../store/app/app";
import {
    cartSelector,
    removeItem,
} from '../../store/cart/cart';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {namespaces} from "../../i18n/i18n.constants";
import {useAppDispatch} from "../../store/store";
import {useTranslation} from "react-i18next";
interface IDialog {
    title:string,
    id:string
}
const DialogComponent = () => {
    const appSelector = useSelector(DialogSelector);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const CartSelector = useSelector(cartSelector);
    let {
        cookie,
    } = CartSelector;
    const {open_dialog,id_dialog,title_dialog,date_dialog} = appSelector;
    return       <Dialog
        open={open_dialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {t('attention'??'',{ ns: namespaces.header})}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t('question_1'??'',{ ns: namespaces.header})}  {title_dialog}? {t('question_2'??'',{ ns: namespaces.header})}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="btnAceptTrash" onClick={()=>{
                dispatch(removeItem({id:id_dialog,cookie:cookie,date:date_dialog}))
            }}>  {t('acepp'??'',{ ns: namespaces.header})}  </Button>
            <Button className="btnCancelTrash" onClick={()=>{dispatch(closeDialog())}} autoFocus>
                {t('cancel'??'',{ ns: namespaces.header})}
            </Button>
        </DialogActions>
    </Dialog>
};

export default DialogComponent;
