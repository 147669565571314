import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import {IHotelCruise,IHotelCruiseState} from "../../models/hotelCruiseLine";
import {IAddons, IPackageState} from "../../models/package";
import {closeBackdrop, openBackdrop} from "../../store/app/app";
import {IClient} from "../../models/client";
const api = process.env.REACT_APP_API_URL
const initialState: IPackageState = {
    addons: [],
    loading: false,
    sku_addon:''
}

export const listAddons = createAsyncThunk<IHotelCruise[],
    { },
    {
        rejectValue: unknown
    }>(
    'package/addonList',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/tour/getAddons`);
            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)

export const validateFolio = createAsyncThunk<boolean,
    {folio:string },
    {
        rejectValue: unknown
    }>(
    'package/folioValidate',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        try {
            const response = await axios.get(`${api}/checkout/validate/${data.folio}/`);
            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)



export const packageSlice = createSlice({
    initialState,
    name: 'packages',
    extraReducers: builder => {
        builder
            .addCase(listAddons.pending, state => {
                state.loading = true
            })
            .addCase(listAddons.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.addons= payload.data.map(addon => ({ ...addon, pax: 0 }));
                state.loading = false
            })
            .addCase(listAddons.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(validateFolio.pending, state => {
                state.loading = true
            })
            .addCase(validateFolio.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(validateFolio.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        changePax: (state, action: PayloadAction<{index:number,pax:number}>) => {
            state.addons[action.payload.index] = { ...state.addons[action.payload.index],pax:action.payload.pax};
        },
        changeSkuAddon: (state, action: PayloadAction<string>) => {
            state.sku_addon=action.payload
        }
    },
})

export const packageSelector = (state: RootState) => {
    return {
        addons: state.package.addons,
        sku_addon:state.package.sku_addon,
        loading: state.package.loading,
    };
};

export const {changePax,changeSkuAddon} = packageSlice.actions;
export const PackageReducer = persistReducer({
    storage: storageSession,
    key: 'package',
}, packageSlice.reducer)
