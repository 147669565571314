import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from 'axios';
import {RootState} from '../rootReducer'
import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import {openBackdrop,closeBackdrop,closeDialog,open_modal_error} from '../app/app'
import {openNotifier} from '../notifier/notifier'
import {
    ICart,
    ICartState,
    IPackagesPost,
    IPackagesItems,
    IFinalBook,
    PaymentInterface
} from "../../models/cart";
import {PackageIds} from "../../models/package";

const api = process.env.REACT_APP_API_URL
 interface IPostInterface{
     error:boolean,
     idReserva_web:number
}
const initialState: ICartState = {
    cart:{
        resume: {
            amount: 0,
            currency: "MXN",
            discount: 0,
            subtotal: 0,
            coupon:false,
            promotion:false,
        },
        items:[],
    },
    cookie:'',
    coupon:'',
    payment_method:'',
    final_book:null,
    id_cart:'',
    is_valid:false,
    loading: false,
    openPayAttempts:{
        idReserva_web: null,
        visible_button:true
    }

}
export const addItem = createAsyncThunk<string,
    { package: IPackagesPost,id:string,cookie:string,currency:string},
    {
        rejectValue: unknown
    }>(
    'cart/addItem',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())

        // @ts-ignore
        const cookieShop=data.cookie
        const axiosConfig = {
            headers: {
                "CookieShop": cookieShop??''
            },

        };

        try {
            const response = await axios.post(`${api}/cart/store/${data.id}`, data.package,axiosConfig);
            thunkApi.dispatch(changeCookie(response.data?.cookieCreated))
            console.log(response)
            thunkApi.dispatch(changeCurrency(data.currency))
            thunkApi.dispatch(lisItems({}))
            // thunkApi.dispatch(closeBackdrop());
            return  response.data;
        } catch (err) {
            console.log(err)
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408||err?.response.status===400){

                // @ts-ignore
                if(err?.response?.data?.message==='Fecha bloqueada'){
                    thunkApi.dispatch(closeBackdrop());
                    thunkApi.dispatch(open_modal_error())
                } else {
                    // @ts-ignore
                    thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                }
            }
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const postCart = createAsyncThunk<IPostInterface,
    {dataPost:any},
    {
        rejectValue: unknown
    }>(
    'cart/postCart',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        // @ts-ignore
        const cookieShop=data.cookie
        const axiosConfig = {
            headers: {
                "CookieShop": cookieShop??''
            },

        };

        try {
            const response = await axios.post(`${api}/checkout/store`, data.dataPost,axiosConfig);
            // thunkApi.dispatch(closeBackdrop());
            return  response.data;
        } catch (err) {
            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408||err?.response.status===400){

                // @ts-ignore
                if(err?.response?.data?.message==='Fecha bloqueada'){
                    thunkApi.dispatch(closeBackdrop());
                    thunkApi.dispatch(open_modal_error())
                } else {
                    // @ts-ignore
                    thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Ha ocurrido un error y el sistema se ha tenido que cerrar'}))
                }
            }
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const validateCoupon = createAsyncThunk<string,
    { coupon:string,cookie:string},
    {
        rejectValue: unknown
    }>(
    'cart/validateCoupon',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        // @ts-ignore
        const cookieShop=data.cookie
        const axiosConfig = {
            headers: {
                "CookieShop": cookieShop??''
            },

        };

        try {
            const response = await axios.post(`${api}/tour/validateCoupon`, {coupon:data.coupon},axiosConfig);

            thunkApi.dispatch(changeCookie(response.data?.cookieCreated))
            thunkApi.dispatch(changeCoupon(data.coupon))
            thunkApi.dispatch(openNotifier({open:true,variant:"success",message:`The coupon ${data.coupon} has been applied correctly`}))
            thunkApi.dispatch(lisItems({}))
            return  response.data;
        } catch (err) {
            thunkApi.dispatch(closeBackdrop());
            // @ts-ignore

            // @ts-ignore
            if(err?.response.status===401 ||err?.response.status===408||err?.response.status===400){
                // @ts-ignore
                thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.errores?.coupon[0]??'there was a problem with the coupon'}))
            }
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const addTransferItem = createAsyncThunk<string,
    { package: IPackagesPost,id:string,cookie:string,transfer:boolean,type_call:boolean,date:string},
    {
        rejectValue: unknown
    }>(
    'cart/editTransfer',
    async (data, thunkApi) => {
        type ObjectKey = keyof typeof PackageIds;
        const valueOfId = data.id as ObjectKey;
        thunkApi.dispatch(openBackdrop())
        // @ts-ignore
        const cookieShop=data.cookie
        const axiosConfig = {
            headers: {
                "CookieShop": cookieShop??''
            },

        };

        try {

            const response = await axios.post(`${api}/cart/store/${PackageIds[valueOfId]}`, data.package,axiosConfig);
            thunkApi.dispatch(removeItem({id:data.id,cookie:response.data?.cookieCreated,date:data.date}))
            thunkApi.dispatch(changeCookie(response.data?.cookieCreated))

            // thunkApi.dispatch(closeBackdrop());
            return  response.data;
        } catch (err) {
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const editItem = createAsyncThunk<IPackagesItems,
    { adult: number,children:number,id:string,cookie:string,date_operation:string,currency:string },
    {
        rejectValue: unknown
    }>(
    'cart/editItemAdult',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        // @ts-ignore
        const cookieShop=data.cookie
        const axiosConfig = {
            headers: {
                "CookieShop": cookieShop
            },

        };

        try {
            const response = await axios.put(`${api}/cart/${data.id}`,{
                cookieCreated:data.cookie,
                adult:data.adult,
                children:data.children,
                date_operation:data.date_operation,
                new_date_operation:null,
                currency:data.currency
            },axiosConfig);
             thunkApi.dispatch(changeCookie(response.data.cookieCreated))
             thunkApi.dispatch(lisItems({}))

            return response.data;
        } catch (err) {
            // @ts-ignore
            thunkApi.dispatch(openNotifier({open:true,variant:"error",message:err?.response?.data?.message??'Ha ocurrido un error'}))
            thunkApi.dispatch(closeBackdrop())
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)


export const editItemDate = createAsyncThunk<IPackagesItems,
    { date: number,id:string,cookie:string },
    {
        rejectValue: unknown
    }>(
    'cart/editItemDate',
    async (data, thunkApi) => {
        // @ts-ignore
        const cookieShop=data.cookie?.cookieCreated

        try {

            const response = await axios.put(`${api}/cart/${data.id}`, {  params: {
                    cookieCreated: data.cookie,
                    date_operation:data.date
                }});
            return response.data;
        } catch (err) {
            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)



export const removeItem = createAsyncThunk<IPackagesItems,
    { id:string,cookie:string,date:string },
    {
        rejectValue: unknown
    }>(
    'cart/removeItem',
    async (data, thunkApi) => {
        // @ts-ignore
        const cookieShop=data.cookie
        const axiosConfig = {
            headers: {
                "CookieShop": cookieShop
            },

        };
        try {
            // const response = await axios.delete(`${api}/cart/${data.id}`,{});
            const response= await axios.delete(`${api}/cart/${data.id}`, {
                headers: {
                    "CookieShop": cookieShop
                },
                data: {
                    date_operation: data.date
                }
            });
            thunkApi.dispatch(changeCookie(response.data.cookieCreated))
            thunkApi.dispatch(closeDialog())
            thunkApi.dispatch(lisItems({}))
            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const lisItems = createAsyncThunk<ICart,
    { },
    {
        rejectValue: unknown
    }>(
    'cart/listItems',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        // @ts-ignore
        const cookieShop=thunkApi.getState().cart.cookie
        try {

            const axiosConfig = {
                headers: {
                    "CookieShop": cookieShop
                },

            };

            const response = await axios.get(`${api}/cart/list`,axiosConfig);

            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)

export const getStatusOpenPay = createAsyncThunk<PaymentInterface,
    {id:string },
    {
        rejectValue: unknown
    }>(
    'cart/getPaymentStatus',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        // @ts-ignore
        const cookieShop=thunkApi.getState().cart.cookie
        try {

            const axiosConfig = {
                headers: {
                    "CookieShop": cookieShop
                },

            };

            const response = await axios.get(`${api}/checkout/getOrderOpenPay/${data.id}`,axiosConfig);

            thunkApi.dispatch(closeBackdrop());
            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const currencyChange = createAsyncThunk<string,
    {currency:string},
    {
        rejectValue: unknown
    }>(
    'cart/changeCurrency',
    async (data, thunkApi) => {
        thunkApi.dispatch(openBackdrop())
        // @ts-ignore
        const cookieShop=thunkApi.getState().cart.cookie
        try {

            const axiosConfig = {
                headers: {
                    "CookieShop": cookieShop
                },

            };
            const response = await axios.put(`${api}/cart/udpateCurrency`,{currency:data.currency},axiosConfig);
            thunkApi.dispatch(changeCookie(response.data.cookieCreated))
            thunkApi.dispatch(lisItems({}))

            return response.data;
        } catch (err) {

            // @ts-ignore
            return thunkApi.rejectWithValue(err.response.data)
        }
    }
)
export const cartSlice = createSlice({
    initialState,
    name: 'cart',
    extraReducers: builder => {
        // @ts-ignore
        builder
            .addCase(addItem.pending, state => {
                state.loading = true
            })
            .addCase(addItem.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.cookie=payload.cookieCreated
                state.loading = false
            })
            .addCase(addItem.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(currencyChange.pending, state => {
                state.loading = true
            })
            .addCase(currencyChange.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.cookie=payload.cookieCreated
                state.loading = false
            })
            .addCase(currencyChange.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(addTransferItem.pending, state => {
                state.loading = true
            })
            .addCase(addTransferItem.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.cookie=payload.cookieCreated
                state.loading = false
            })
            .addCase(addTransferItem.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(editItem.pending, state => {
                state.loading = true
            })
            .addCase(editItem.fulfilled, (state, {payload}) => {
                state.loading = false
            })
            .addCase(editItem.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(removeItem.pending, state => {
                state.loading = true
            })
            .addCase(removeItem.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.cookie=payload.cookieCreated
                state.loading = false


            })
            .addCase(removeItem.rejected, (state, action) => {
                state.loading = false

            })
            .addCase(lisItems.pending, state => {
                state.loading = true
            })
            .addCase(lisItems.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.cart=payload.cookieResponse
                state.loading = false
            })
            .addCase(lisItems.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(validateCoupon.pending, state => {
                state.loading = true
            })
            .addCase(validateCoupon.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.cookie=payload.cookieCreated
                // @ts-ignore
                state.cart.cart.coupon=true
                state.loading = false
            })
            .addCase(validateCoupon.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(getStatusOpenPay.pending, state => {
                state.loading = true
            })
            .addCase(getStatusOpenPay.fulfilled, (state, {payload}) => {
              // @ts-ignore

               // @ts-ignore

                state.loading = false
            })
            .addCase(getStatusOpenPay.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(postCart.pending, state => {
                state.loading = true
            })
            .addCase(postCart.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.openPayAttempts.idReserva_web=payload.idReserva_web
                state.openPayAttempts.visible_button=!payload.error
                state.loading = false
            })
            .addCase(postCart.rejected, (state, action) => {
                state.loading = false
            })
    },
    reducers: {
        loading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        changeCookie:(state,action:PayloadAction<string>)=>{
            state.cookie=action.payload
        },
        changeCurrency:(state,action:PayloadAction<string>)=>{
            state.cart.resume.currency=action.payload
        },
        changeIdCart:(state,action:PayloadAction<string>)=>{
            state.id_cart=action.payload
        },
        resetCartItems:(state)=>{
            state.cart=initialState.cart
            state.cookie=initialState.cookie
            state.payment_method=initialState.payment_method
            state.coupon=initialState.coupon
        },
        fillFinalBook:(state,action:PayloadAction<IFinalBook>)=>{
            state.final_book=action.payload
        },
        resetFinalBook:(state)=>{
            state.final_book=initialState.final_book
        },
        changeCoupon:(state,action:PayloadAction<string>)=>{
            state.coupon=action.payload
        },
        changePaymentMethod:(state,action:PayloadAction<string>)=>{
            state.payment_method=action.payload
        }
    },
})
export const cartSelector = (state: RootState) => state.cart;
export const cartResumeSelector=(state: RootState)=>{
    return {
        resume_amount: state.cart.cart.resume.amount,
        resume_adult:(state.cart.cart.items.reduce(
            (accumulator: number, currentValue: IPackagesItems) => accumulator + (currentValue.type==='services'?parseInt(currentValue.adult):0),
            0)),
        resume_addon:(state.cart.cart.items.reduce(
            (accumulator: number, currentValue: IPackagesItems) => accumulator + (currentValue.type==='add-ons'?parseInt(currentValue.adult):0),
            0)),
        resume_children: state.cart.cart.items.reduce(
            (accumulator: number, currentValue: IPackagesItems) => accumulator + currentValue.children,
            0),
        resume_currency:  state.cart.cart.resume.currency,
        resume_discount:  state.cart.cart.resume.discount,
        resume_subtotal:  state.cart.cart.resume.subtotal,
        resume_promotion: state.cart.cart.resume.promotion,
        resume_coupon: state.cart.cart.coupon,
        resume_coupon_name: state.cart.coupon
    }
}
export const cartPaymentSelector=(state: RootState)=>{
    return {
        hotel_cruise_line: state.client.client.point_sale_id,
        name: state.client.client.name,
        last_name: state.client.client.last_name,
        email: state.client.client.email,
        phone_number: state.client.client.phone_number,
        folio: state.client.folio,
        state_province: state.client.client.state_province,
        country: state.client.client.country,
        city:state.client.client.city,
        currency:state.cart.cart.resume.currency,
        origin_sale:state.app.origin_sale,
        coupon: state.cart.coupon,
        items: state.cart.cart.items
    }
}
export const finalBookSelector=(state: RootState)=>{
    return {
        amount:state.cart.final_book?.amount,
        city:state.cart.final_book?.city,
        country:state.cart.final_book?.country ,
        crated_at: state.cart.final_book?.crated_at,
        details:state.cart.final_book?.details,
        email:state.cart.final_book?.email,
        full_name:state.cart.final_book?.full_name ,
        hotel_cruise:state.cart.final_book?.hotel_cruise ,
        image:state.cart.final_book?.image,
        invoice_number:state.cart.final_book?.invoice_number,
        payment_method:state.cart.final_book?.payment_method,
        phone: state.cart.final_book?.phone,
        plain_file_pdf: state.cart.final_book?.plain_file_pdf,
        state:state.cart.final_book?.state,
        transaction_id:state.cart.final_book?.transaction_id,
        transaction_status: state.cart.final_book?.transaction_status??'',
        currency:state.cart.final_book?.currency,
        date_operation:state.cart.final_book?.date_operation
    }
}

export const cartPostSelector=(state: RootState)=>{
    return {
        hotel_cruise_line:  state.client.client.point_sale_id,
        name: state.client.client.name,
        last_name: state.client.client.last_name,
        email: state.client.client.email,
        phone_number: state.client.client.phone_number,
        folio: state.client.folio,
        state_province: state.client.client.state_province,
        country: state.client.client.country,
        city:state.client.client.city,
        currency:state.cart.cart.resume.currency,
        origin_sale:state.app.origin_sale,
        coupon: state.cart.coupon,
        items: state.cart.cart.items,
        idReserva_web: state.cart.openPayAttempts.idReserva_web,

    }
}
export const OpenPaySelector = (state: RootState) => {
    return {

        idReserva_web: state.cart.openPayAttempts.idReserva_web,
        visible_button: state.cart.openPayAttempts.visible_button,
    }
}
export const {
    loading,
    changeCookie,
    changeCurrency,
    changePaymentMethod,
    changeIdCart,
    changeCoupon,
    resetCartItems,
    fillFinalBook,
    resetFinalBook
} = cartSlice.actions;
export const CartReducer = persistReducer({
    storage: storageSession,
    key: 'cart',
    blacklist: ['id_cart']
    // blacklist: ['is_valid,id_cart']
}, cartSlice.reducer)
