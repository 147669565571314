export const namespaces = {
    header: "header",
    check_your_cart: "check_your_cart",
    client_information: "client_information",
    mistakes: "mistakes",
    add_ons: "add_ons",
    payment_process:"payment_process",
    purchase_details:"purchase_details",
    steps:"steps",
    final_book:"final_book",
    open_pay:"open_pay",
    thank_you:"thank_you",
    error_open_pay:"error_open_pay",
    modal_addon:"modal_addon"

};

export const languages = {
    es: "es",
    en: "en",
};
