import React, {useEffect,useState} from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import {ticketFooter,ticketHeader} from "../../assets/images/index";
import {finalBookSelector} from "../../store/cart/cart";
import {resetCartItems,resetFinalBook} from "../../store/cart/cart";
import {changeUnValidForm,resetClient} from "../../store/clientInfo/clientInfo";
import {IPackagesPost, IResponsePost} from "../../models/cart"
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../store/store";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

export const FinalBook=()=> {
    const FinalBookSelector = useSelector(finalBookSelector);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    let {
        amount,
        city,
        country,
        crated_at,
        details,
        email,
        full_name,
        hotel_cruise,
        image,
        invoice_number,
        phone,
        plain_file_pdf,
        currency,
        date_operation,
    } = FinalBookSelector;
    useEffect(() => {

        dispatch((changeUnValidForm()));
        dispatch((resetCartItems()));
        dispatch((resetClient()));
        window.sessionStorage.removeItem("persist:app");
        window.sessionStorage.removeItem("persist:client");
        window.sessionStorage.removeItem("persist:hotelCruiseLine");
        window.sessionStorage.removeItem("persist:package");
        window.sessionStorage.removeItem("persist:cart");
        window.sessionStorage.clear();
    }, []);

    return (
        <Grid md={11}>
            <div className="es-wrapper-color" style={{backgroundColor:'#F6F6F6'}}>
                <table className="es-wrapper" width="100%" cellSpacing="0" cellPadding="0"
                       style={{borderSpacing:'0px',padding:0,margin:0,width:'100%',height:'100%',backgroundRepeat:'repeat',backgroundPosition:'center top',backgroundColor:'#ffffff'}}>

                    <tr key={Math.random().toString().replace("0.", "")}>
                        <td valign="top" style={{padding:0,margin:0}}>
                            <table cellPadding="0" cellSpacing="0" className="es-header" align="center"
                                   style={{borderCollapse:'collapse',borderSpacing:'0px',tableLayout:'fixed',width:'100%',backgroundColor:'transparent',backgroundRepeat:'repeat',backgroundPosition:'center top'}}>
                                <tr key={Math.random().toString().replace("0.", "")}>
                                    <td align="center" style={{padding:0,margin:0}}>
                                        <table bgcolor="#ffffff" className="es-header-body" align="center"
                                               cellPadding="0" cellSpacing="0"
                                               style={{borderCollapse:'collapse',borderSpacing:'0px',backgroundColor:'#FFFFFF',width:'700px'}}>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left" style={{padding:0,margin:0,paddingBottom:'15px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p0r es-m-p20b" valign="top"
                                                                align="center"
                                                                style={{padding:0,margin:0,width:'700px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="center"
                                                                            style={{padding:0,margin:0,fontSize:'0px'}}>
                                                                            <img
                                                                                className="adapt-img"
                                                                                src={ticketHeader}
                                                                                alt="Banner Ticket"
                                                                                style={{display:'block',border:0,outline:'none',textDecoration:'none'}}
                                                                                width="700"
                                                                                title="Banner Ticket"/></td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <table className="es-content" cellSpacing="0" cellPadding="0" align="center"
                                   style={{borderCollapse:'collapse',borderSpacing:'0px',tableLayout:'fixed',width:'100%'}}>
                                <tr key={Math.random().toString().replace("0.", "")}>
                                    <td align="center" style={{padding:0,margin:0}}>
                                        <table className="es-content-body"
                                               style={{borderCollapse:'collapse',borderSpacing:'0px',backgroundColor:'#ffffff',width:'700px'}}
                                               cellSpacing="0" cellPadding="0" bgcolor="#ffffff" align="center">
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'10px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table cellPadding="0" cellSpacing="0" className="es-left"
                                                           align="left"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'left'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#783dbe',fontSize:'15px'}}>
                                                                            <strong>
                                                                                {t('booking_number'??'',{ ns: namespaces.final_book})}
                                                                            </strong></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',lineHeightStep:'exactly',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{invoice_number}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table cellPadding="0" cellSpacing="0" className="es-right"
                                                           align="right"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'right'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#0097dc',fontSize:'15px'}}>
                                                                            <strong>
                                                                                {t('country_origin'??'',{ ns: namespaces.final_book})}
                                                                            </strong>
                                                                        </p></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{country}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'10px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table cellPadding="0" cellSpacing="0" className="es-left"
                                                           align="left"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'left'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#783dbe',fontSize:'15px'}}>
                                                                            <strong>
                                                                                {t('date_of_purchase'??'',{ ns: namespaces.final_book})}
                                                                            </strong>
                                                                        </p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{crated_at.substring(0, 10)}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table cellPadding="0" cellSpacing="0" className="es-right"
                                                           align="right"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'right'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'21px',color:'#0097dc',fontSize:'15px'}}>
                                                                            <strong>
                                                                                {t('city'??'',{ ns: namespaces.final_book})}
                                                                            </strong></p></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{city}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'10px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table cellPadding="0" cellSpacing="0" className="es-left"
                                                           align="left"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'left'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'21px',color:'#783dbe',fontSize:'15px'}}>
                                                                            <strong>
                                                                                {t('name'??'',{ ns: namespaces.final_book})}
                                                                            </strong></p></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{full_name}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table cellPadding="0" cellSpacing="0" className="es-right"
                                                           align="right"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'right'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#0097dc',fontSize:'15px'}}>
                                                                            <strong>
                                                                                {t('package_price'??'',{ ns: namespaces.final_book})}
                                                                            </strong></p></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>${amount} {currency}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'10px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table cellPadding="0" cellSpacing="0" className="es-left"
                                                           align="left"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'left'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#783dbe',fontSize:'15px'}}>
                                                                            <strong>EMAIL</strong></p></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{email}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table cellPadding="0" cellSpacing="0" className="es-right"
                                                           align="right"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'right'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#0097dc',fontSize:'15px'}}>
                                                                            <strong>
                                                                                {t('date_arrival'??'',{ ns: namespaces.final_book})}
                                                                            </strong></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{date_operation}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'10px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table cellPadding="0" cellSpacing="0" className="es-left"
                                                           align="left"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'left'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#783dbe',fontSize:'15px'}}>
                                                                            <strong>  {t('phone_number'??'',{ ns: namespaces.final_book})}</strong></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}
                                                                            }>{phone}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                    <table cellPadding="0" cellSpacing="0" className="es-right"
                                                           align="right"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px',float:'right'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td align="left"
                                                                style={{padding:0,margin:0,width:'310px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'23px',color:'#0097dc',fontSize:'15px'}}>
                                                                            <strong> {t('cruise_line'??'',{ ns: namespaces.final_book})}</strong>
                                                                        </p></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>{hotel_cruise?.name}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{margin:0,paddingBottom:'20px',paddingTop:'25px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p0r es-m-p20b" valign="top"
                                                                align="center"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    {
                                                                        details?.map((detail:IResponsePost,index:number)=>{
                                                                            return <tr key={Math.random().toString().replace("0.", "")}><td align="center"
                                                                                    style={{padding:0,margin:0,paddingLeft:'30px',paddingRight:'30px'}}>
                                                                                    <p style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>
                                                                                        <strong>&gt; {detail?.name}</strong> &nbsp;
                                                                                        {t('adult'??'',{ ns: namespaces.final_book})} {detail?.adult} {t('child'??'',{ ns: namespaces.final_book})} {detail?.children} <strong>{t('date_of_arrival'??'',{ ns: namespaces.final_book})}: {detail?.date_operation}</strong> Total: ${detail?.amount} {currency}</p></td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                    {/*<tr key={Math.random().toString().replace("0.", "")}>*/}

                                                                    {/*    <td align="center"*/}
                                                                    {/*        style={{padding:0,margin:0,paddingLeft:'30px',paddingRight:'30px'}}>*/}
                                                                    {/*        <p style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#333333',fontSize:'14px'}}>*/}
                                                                    {/*            <strong>&gt; Amazing Beach*/}
                                                                    {/*                Break</strong> &nbsp; 1*/}
                                                                    {/*            Adult 0 Child <strong>Date of*/}
                                                                    {/*            arrival:</strong> Sat 3 Sep,*/}
                                                                    {/*            2022 Total: $95.00 us</p></td>*/}
                                                                    {/*</tr>*/}
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <table cellPadding="0" cellSpacing="0" className="es-content" align="center"
                                   style={{borderCollapse:'collapse',borderSpacing:'0px',tableLayout:'fixed',width:'100%'}}>
                                <tr key={Math.random().toString().replace("0.", "")}>
                                    <td align="center" style={{padding:0,margin:0}}>
                                        <table bgcolor="#ffffff" className="es-content-body" align="center"
                                               cellPadding="0" cellSpacing="0"
                                               style={{borderCollapse:'collapse',borderSpacing:'0px',backgroundColor:'#FFFFFF',width:'700px'}}>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'15px',paddingBottom:'15px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td valign="top" align="center"
                                                                style={{padding:0,margin:0,width:'700px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="center"
                                                                            style={{padding:0,margin:0,paddingTop:'10px',paddingBottom:'10px',backgroundColor:'#783dbe'}}>
                                                                            <p style={{margin:0,textSizeAdjust:'none',fontFamily:'NunitoBlack',lineHeight:'21px',color:'#ffffff',fontSize:'14px'}}>
                                                                                <b><span
                                                                                    style={{fontSize:'15px'}}>{t('important_facts'??'',{ ns: namespaces.final_book})}</span>&nbsp;
                                                                                </b>
                                                                            </p></td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0}}>
                                                                            <ol type="1" style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}>
                                                                                <li>{t('important_1'??'',{ ns: namespaces.final_book})}
                                                                                </li>
                                                                                <li>{t('important_2'??'',{ ns: namespaces.final_book})}
                                                                                </li>
                                                                                <li>{t('important_3'??'',{ ns: namespaces.final_book})}
                                                                                </li>
                                                                                <li>{t('important_4'??'',{ ns: namespaces.final_book})}
                                                                                </li>
                                                                                <li>{t('important_5'??'',{ ns: namespaces.final_book})}
                                                                                </li>
                                                                            </ol>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <h2 className="name"
                                                                                style={{margin:0,lineHeight:'19px',fontFamily:'NunitoBlack',fontSize:'16px',fontStyle:'normal',fontWeight:'normal',color:'#666666',textAlign:'left'}}>
                                                                                <strong>{t('cancellation_policy'??'',{ ns: namespaces.final_book})}</strong>
                                                                            </h2></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0}}>
                                                                            <ul className="description"
                                                                                style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}>
                                                                                <li key={'ul-1-1'}>{t('cancellation_policy_1'??'',{ ns: namespaces.final_book})}</li>
                                                                                <li key={'ul-1-2'}>{t('cancellation_policy_2'??'',{ ns: namespaces.final_book})}</li>
                                                                                <li key={'ul-1-3'}>{t('cancellation_policy_3'??'',{ ns: namespaces.final_book})}</li>
                                                                                <li key={'ul-1-4'}>{t('cancellation_policy_4'??'',{ ns: namespaces.final_book})}</li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <h2 className="name"
                                                                                style={{margin:0,lineHeight:'19px',fontFamily:'NunitoBlack',fontSize:'16px',fontStyle:'normal',fontWeight:'normal',color:'#666666',textAlign:'left'}}>
                                                                                <strong>{t('beach_break_transfer_service'??'',{ ns: namespaces.final_book})}</strong>
                                                                            </h2></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0}}>
                                                                            <p
                                                                            className="description"
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}> {t('beach_break_transfer_service_1'??'',{ ns: namespaces.final_book})}
                                                                                {t('beach_break_transfer_service_2'??'',{ ns: namespaces.final_book})}
                                                                                {t('beach_break_transfer_service_3'??'',{ ns: namespaces.final_book})} </p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <h2 className="name"
                                                                                style={{margin:0,lineHeight:'19px',fontFamily:'NunitoBlack',fontSize:'16px',fontStyle:'normal',fontWeight:'normal',color:'#666666',textAlign:'left'}}>
                                                                                <strong>{t('for_guest_hotel'??'',{ ns: namespaces.final_book})}</strong>
                                                                            </h2>
                                                                            <ul style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}>
                                                                                <li key={'ul-2-1'}>{t('for_guest_hotel_1'??'',{ ns: namespaces.final_book})}</li>
                                                                                <li key={'ul-2-2'}>{t('for_guest_hotel_2'??'',{ ns: namespaces.final_book})}</li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingRight:'30px',paddingLeft:'35px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'635px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <h2 className="name"
                                                                                style={{margin:0,lineHeight:'19px',fontFamily:'NunitoBlack',fontSize:'16px',fontStyle:'normal',fontWeight:'normal',color:'#666666',textAlign:'left'}}>
                                                                                <strong>{t('for_dance_salsa_beach'??'',{ ns: namespaces.final_book})}</strong>
                                                                            </h2></td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l"
                                                                            align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingRight:'5px',paddingBottom:'10px'}}>
                                                                            <p style= {{margin:0,textSizeAdjust:'none',fontFamily:'NotoRegular',lineHeight:'21px',color:'#666666',fontSize:'14px',backgroundColor:'transparent'}}
                                                                               className="title2">{t('for_dance_salsa_beach_1'??'',{ ns: namespaces.final_book})} </p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <h2 className="name"
                                                                                style={{margin:0,lineHeight:'19px',fontFamily:'NunitoBlack',fontSize:'16px',fontStyle:'normal',fontWeight:'normal',color:'#666666',textAlign:'left'}}>
                                                                                <strong>{t('cozumel_snorkel_tour_coral_reefs_el_cielo_service'??'',{ ns: namespaces.final_book})}</strong>
                                                                            </h2>
                                                                            <ul style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}>
                                                                                <li key={'ul-3-1'}>{t('cozumel_snorkel_tour_coral_reefs_el_cielo_service_1'??'',{ ns: namespaces.final_book})}</li>
                                                                                <li key={'ul-3-2'}>{t('cozumel_snorkel_tour_coral_reefs_el_cielo_service_2'??'',{ ns: namespaces.final_book})}</li>
                                                                                <li key={'ul-3-3'}>{t('cozumel_snorkel_tour_coral_reefs_el_cielo_service_3'??'',{ ns: namespaces.final_book})}</li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <h2 className="name"
                                                                                style={{margin:0,lineHeight:'19px',fontFamily:'NunitoBlack',fontSize:'16px',fontStyle:'normal',fontWeight:'normal',color:'#666666',textAlign:'left'}}>
                                                                                <strong> {t('meeting_points_for_peach_break_with_transfer'??'',{ ns: namespaces.final_book})} </strong>
                                                                            </h2>

                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingRight:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <p style={{margin:0,fontWeight:100,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#999999',fontSize:'15px'}}
                                                                               className="title2"><strong>{t('from_puerta_maya_pier'??'',{ ns: namespaces.final_book})}</strong></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            className="description"
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}>{t('from_puerta_maya_pier_1'??'',{ ns: namespaces.final_book})}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingRight:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <p style={{margin:0,fontWeight:100,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#999999',fontSize:'15px'}}
                                                                               className="title2"><strong> {t('from_internacional_pier'??'',{ ns: namespaces.final_book})}</strong></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            className="description"
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}> {t('from_internacional_pier_1'??'',{ ns: namespaces.final_book})}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left"
                                                    style={{padding:0,margin:0,paddingTop:'20px',paddingLeft:'30px',paddingRight:'30px'}}>
                                                    <table width="100%" cellSpacing="0" cellPadding="0"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td className="es-m-p20b" align="left"
                                                                style={{padding:0,margin:0,width:'640px'}}>
                                                                <table width="100%" cellSpacing="0"
                                                                       cellPadding="0" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0,paddingTop:'5px',paddingRight:'5px',paddingBottom:'10px',backgroundColor:'transparent'}}>
                                                                            <p style={{margin:0,fontWeight:100,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#999999',fontSize:'15px'}}
                                                                               className="title2"><strong>{t('from_punta_langosta_pier'??'',{ ns: namespaces.final_book})}</strong></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td className="es-m-txt-l" align="left"
                                                                            style={{padding:0,margin:0}}><p
                                                                            className="description"
                                                                            style={{margin:0,textSizeAdjust:'none',fontFamily:'helvetica',lineHeight:'21px',color:'#666666',fontSize:'14px'}}>{t('from_punta_langosta_pier_1'??'',{ ns: namespaces.final_book})}</p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <table cellPadding="0" cellSpacing="0" className="es-footer" align="center"
                                   style={{borderCollapse:'collapse',borderSpacing:'0px',width:'100%',backgroundColor:'transparent',backgroundRepeat:'repeat',backgroundPosition:'center top'}}>
                                <tr key={Math.random().toString().replace("0.", "")}>
                                    <td align="center" style={{padding:0,margin:0}}>
                                        <table className="es-footer-body" align="center"
                                               cellPadding="0" cellSpacing="0"
                                               style={{borderCollapse:'collapse',borderSpacing:'0px',backgroundColor:'#FFFFFF',width:'700px'}}>
                                            <tr key={Math.random().toString().replace("0.", "")}>
                                                <td align="left" style={{padding:0,margin:0,paddingTop:'20px'}}>
                                                    <table cellPadding="0" cellSpacing="0" width="100%"
                                                           style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                        <tr key={Math.random().toString().replace("0.", "")}>
                                                            <td align="center" valign="top"
                                                                style={{padding:0,margin:0,width:'700px'}}>
                                                                <table cellPadding="0" cellSpacing="0"
                                                                       width="100%" role="presentation"
                                                                       style={{borderCollapse:'collapse',borderSpacing:'0px'}}>
                                                                    <tr key={Math.random().toString().replace("0.", "")}>
                                                                        <td align="center"
                                                                            style={{padding:0,margin:0,fontSize:'0px'}}>
                                                                            <img
                                                                                className="adapt-img"
                                                                                src={ticketFooter}
                                                                                alt="Ticket Footer"
                                                                                style={{display:'block',border:0,outline:'none',textDecoration:'none'}}
                                                                                width="700"
                                                                                title="Ticket Footer"/></td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </Grid>
    );
}

