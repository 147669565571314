import * as React from 'react';
import {StepIconProps} from "@mui/material/StepIcon";
import CheckIcon from "@mui/icons-material/Check";
import {styled} from "@mui/material/styles";
const CustomStep = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 41,
    height: 41,
    display: 'flex',
    border: '4px solid #fff',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient( 55deg, #8A3BBB 0%, #18A6CA 88%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 55deg, #8A3BBB 0%, #18A6CA 88%)',
        border: '4px solid #fff',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
}));
export const  StepIcon=(props: StepIconProps)=> {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <CheckIcon />,
        2: <CheckIcon />,
        3: <CheckIcon />,
        4: <CheckIcon />,
        5: <CheckIcon />,
    };

    return (
        <CustomStep ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </CustomStep>
    );
}
