import React from 'react';
import {Header} from "../../components/header/header";
import {Footer} from "../../components/footer/footer"

interface LayoutDefaultProps {
    children?: React.ReactElement;
}

export const Layout = ({children}: LayoutDefaultProps) => {
    return (
        <>
            <Header></Header>
            <main>{children}</main>
             <Footer></Footer>
        </>
    )
}
