import React,{useEffect} from 'react';
import {useTranslation} from "react-i18next";
import "./footer.css"
import IcoMoon from "react-icomoon";
import iconSet from "./selection.json";
export const  Footer=()=> {
    const { i18n } = useTranslation();

    return(

<footer className="footer">
    <div className="uk-container footer-wrapper">
        <div className="footer-brand-details">
            <div className="footer-details footer_address"> <a
                    href="https://www.playamia.com/tracking/click-location-map.aspx"
                    target="_blank">Carretera Costera Sur Km. 15,<span className="city">San Miguel de Cozumel</span> <span
                    className="state">Quintana Roo</span> <span className="zipcde">77600</span></a>
            </div>
            <div className="footer-details footer_email"> <a href="mailto:reservations@playamia.com"
                                                                      rel="nofollow">reservations@playamia.com</a>
            </div>
            <div className="footer-details footer_phone">
                <p>Toll Free <span className="phone-text">Cancún/Riviera Maya/Playa del Carmen:</span> </p> <a
                    className="toll-text" href="tel:800-212-9430" target="_blank" rel="nofollow">800-212-9430</a>
            </div>
        </div>
        <div className="footer_social">

            <a href="https://api.whatsapp.com/send?phone=529875648638&amp;text=Hello!%20I%20would%20like%20more%20information%20about%20Playamia%20tours"
               className="social-item" title="whatsapp" target="_blank" rel="nofollow">
                <IcoMoon iconSet={iconSet}  icon={"whatsapp"}/>
            </a>
            <a href="https://www.tripadvisor.com/Attraction_Review-g150809-d1064411-Reviews-Playa_Mia_Grand_Beach_Park-Cozumel_Yucatan_Peninsula.html"
               className="social-item" title="TripAdvisor" target="_blank" rel="nofollow">
                <IcoMoon iconSet={iconSet}  icon={"tripadvisor"}/>
            </a>
            <a href="https://www.facebook.com/PlayaMiaCozumel" className="social-item" title="Facebook" target="_blank"
               rel="nofollow">  <IcoMoon iconSet={iconSet}  icon={"facebook"}/> </a>
            <a href="https://www.instagram.com/playamiacozumel/" className="social-item" title="Instagram" target="_blank"
               rel="nofollow"> <IcoMoon iconSet={iconSet}  icon={"instagram"}/> </a>
            <a href="https://www.youtube.com/user/PlayaMiaCozumel" className="social-item" title="Youtube" target="_blank"
               rel="nofollow">  <IcoMoon iconSet={iconSet}  icon={"youtube"}/> </a>
            <a href="https://twitter.com/PlayaMiaBeach" className="social-item" title="Twitter" target="_blank"
               rel="nofollow"> <IcoMoon iconSet={iconSet}  icon={"twitter"}/> </a>
            <a href="https://twitter.com/PlayaMiaBeach" className="social-item" title="Twitter" target="_blank"
               rel="nofollow"> <IcoMoon iconSet={iconSet}  icon={"linkedin2"}/>
            </a>
            <a href="https://twitter.com/PlayaMiaBeach" className="social-item" title="Twitter" target="_blank"
               rel="nofollow"> <IcoMoon iconSet={iconSet}  icon={"tiktok"}/> </a>
            <a href="https://twitter.com/PlayaMiaBeach" className="social-item" title="Twitter" target="_blank"
               rel="nofollow"> <IcoMoon iconSet={iconSet}  icon={"google"}/> </a>
        </div>
        <div className="footer-menu"> <a href="https://www.playamia.com/site-map"
                                     className="footer-link nav "
        >sitemap</a>
            <a href="https://www.playamia.com/restrictions"
               className="footer-link nav "
            >Restrictions</a>
            <a href="https://www.playamia.com/cancellation-policy"
               className="footer-link nav "
            >Cancellation Policy</a>
            <a href="https://www.playamia.com/privacy-policy"
               className="footer-link nav "
            >Privacy Policy</a>
            <a href="https://www.playamia.com/terms-conditions"
               className="footer-link nav "
            >Terms &amp; Conditions</a> <a
                    href="https://www.playamia.com/email-offers"
                    className="footer-link nav "
            >Email Offers</a>
            <a href="https://www.playamia.com/accessibility"
               className="footer-link nav "
            >Accessibility</a>
        </div>
        <div className="footer_copyright footer_copyright--home"> <span>© 2022 Digital Presence Powered
                        by</span>
            <a className="copy-home" href="https://www.milestoneinternet.com/" target="_blank"
               rel="nofollow">Milestone Inc.</a>
        </div>
        <div className="footer_copyright footer_copyright--inner">
            <span>© 2022 All Rights Reserved</span>
        </div>
    </div>
</footer>
    )
}
