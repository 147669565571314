import React, { useEffect, useState, useReducer,useRef } from 'react';
import  './formOpenPay.css';
import {useSelector} from "react-redux";
import {
    cartPaymentSelector,
    cartResumeSelector,
    cartSelector,
    fillFinalBook,
    OpenPaySelector
} from '../../store/cart/cart'
import {clientSelector, fillIdPayment} from '../../store/clientInfo/clientInfo'
import {openBackdrop,closeBackdrop} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {openNotifier} from '../../store/notifier/notifier'
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
const api = process.env.REACT_APP_API_URL
const merchantID = process.env.REACT_APP_MERCHANT_ID
const publicKeyID = process.env.REACT_APP_KEY_PUBLIC_ID

interface IValidate{
    card_number:string,
    cvv:string,
    expire_year:string
    expire_month:string
}
export const  FormOpenPay=()=> {
    const [isDisabled, setDisabled] = useState(false);
    let cardNumberInput = useRef<HTMLInputElement>(null);
    let cvcInput = useRef<HTMLInputElement>(null);
    let expiryMonthInput = useRef<HTMLInputElement>(null);
    let expiryYearInput = useRef<HTMLInputElement>(null);
    let cardNameInput = useRef<HTMLInputElement>(null);
    const [errorValidateCardNumber, setErrorValidateCardNumber] = useState(false);
    const [errorValidateCVC, setErrorValidateCVC] = useState(false);
    const [errorValidateExpiry, setErrorValidateExpiry] = useState(false);
    const [cardType, setCardType] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [nameIsEmpty, setNameIsEmpty] = useState(false);
    const dispatchApp = useAppDispatch();
    let dataPayment= useSelector(cartPaymentSelector);
    const ClientSelector = useSelector(clientSelector);
    let {idReserva_web,visible_button}= useSelector(OpenPaySelector);
    let {
        folio,
    } = ClientSelector;
    // @ts-ignore
    window.OpenPay.setId(merchantID);
    // @ts-ignore
    window.OpenPay.setApiKey(publicKeyID);
    // @ts-ignore
    window.OpenPay.setSandboxMode(false);

    let validateInfoCard=(props:IValidate)=>{

         // @ts-ignore
         if(!window.OpenPay.card.validateCardNumber(props.card_number)){
            setErrorValidateCardNumber(true)
        } else {
            setErrorValidateCardNumber(false)
        }
         // @ts-ignore
        if(!window.OpenPay.card.validateCVC(props.cvv)){
            setErrorValidateCVC(true)
        } else {
            setErrorValidateCVC(false)
        }
        // @ts-ignore
        if(!window.OpenPay.card.validateExpiry(props.expire_month, props.expire_year)){
            setErrorValidateExpiry(true)
        } else{
            setErrorValidateExpiry(false)
        }

        // @ts-ignore
        if(window.OpenPay.card.cardType(props.card_number)!==''){
             // @ts-ignore
            setCardType(window.OpenPay.card.cardType(props.card_number))
        }  else {
            setCardType('')
        }
         // @ts-ignore
        if(cardNameInput.current.value===''){
            setNameIsEmpty(true)
        } else{
            setNameIsEmpty(false)
        }
    }
          //Se genera el id de dispositivo
              // @ts-ignore
    let deviceSessionId = window.OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
    const { t } = useTranslation();
    const OnsubmitFormOpenPay=()=>{
        dispatchApp(openBackdrop())
        setDisabled(true)
        // @ts-ignore
        validateInfoCard({card_number:cardNumberInput.current?.value,cvv:cvcInput.current?.value,expire_month:expiryMonthInput.current?.value,expire_year:expiryYearInput.current.value})
           // @ts-ignore
           window.OpenPay.token.extractFormAndCreate('payment-form', success_callbak, error_callbak);
        }

    var success_callbak = function(response:any) {
        setDisabled(false)
        setErrorMessage('')
        var token_id = response.data.id;
        return fetch(`${api}/checkout/storeOpenPay`, {
            method: "POST",
            body: JSON.stringify({
                card_token: token_id,
                device_session_id:deviceSessionId,
                typeCharge: 1,
                idReserva_web: idReserva_web,
            }),
            headers: {
                "content-type": "application/json",
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((openPayResponse) => {
                if(openPayResponse?.id){
                    dispatchApp(fillIdPayment({idPayment:openPayResponse?.id}))
                     window.location.href=openPayResponse?.url
                } else{
                        // @ts-ignore
                        dispatchApp(openNotifier({variant: "error", open: true, message: 'No se pudo realizar el pago'}))
                        dispatchApp(closeBackdrop())
                }

            })
            .catch((err) => {

                dispatchApp(closeBackdrop())
            });
        };

        var error_callbak = function(response:any) {
            dispatchApp(closeBackdrop())
            setDisabled(false)
            var desc = response.data.description != undefined ? response.data.description : response.message;

            setErrorMessage(desc)
       };



    return (
        <>
            <div className="contFormOpenpay">
                <div className="contGridPay">
                <form action="#" method="POST" id="payment-form">
                <div className="grid-pay">
                    <div>
                        <h4 style={{fontWeight:600}}>{t('credit_card'??'',{ ns: namespaces.open_pay})}</h4>
                        <div className="credit"></div>

                    </div>
                    <div>
                        <h4 style={{fontWeight:600}}>{t('debit_card'??'',{ ns: namespaces.open_pay})}</h4>
                        <div className="debit"></div>
                    </div>
                </div>

                <div className="grid-pay">
                    <div className="div">
                        <div><label>{t('owner_name'??'',{ ns: namespaces.open_pay})}</label><input type="text"  ref={cardNameInput} style={{backgroundColor:nameIsEmpty?'#ffecec':''}}  placeholder={t('name_in_card'??'',{ ns: namespaces.open_pay})??''} autoComplete="off" data-openpay-card="holder_name"/></div>
                    </div>
                    <div className="div">
                        <div><label>{t('number_card'??'',{ ns: namespaces.open_pay})}</label><input type="text" maxLength={16} style={{backgroundColor:errorValidateCardNumber?'#ffecec':''}} placeholder={'XXXX XXXX XXXX XXXX'} ref={cardNumberInput} autoComplete="off" data-openpay-card="card_number"/></div>
                    </div>
                </div>

                <div className="grid-pay">
                    <div className="div">
                        <label>{t('expiration_date'??'',{ ns: namespaces.open_pay})}</label>
                        <div className="col2">
                            <input type="text" ref={expiryMonthInput} style={{backgroundColor:errorValidateExpiry?'#ffecec':''}} placeholder={t('month'??'',{ ns: namespaces.open_pay})??''}  data-openpay-card="expiration_month" maxLength={2} />
                            <input type="text" ref={expiryYearInput} style={{backgroundColor:errorValidateExpiry?'#ffecec':''}} placeholder={t('year'??'',{ ns: namespaces.open_pay})??''} data-openpay-card="expiration_year"  maxLength={2}/>
                        </div>
                    </div>
                    <div className="div">
                        <div className="cvv"><label>{t('security_code'??'',{ ns: namespaces.open_pay})}</label>
                            <div className=" half l"><input type="text" ref={cvcInput} style={{backgroundColor:errorValidateCVC?'#ffecec':''}} placeholder={t('three_digit'??'',{ ns: namespaces.open_pay})??''} autoComplete="off" data-openpay-card="cvv2"  maxLength={3}/></div>
                        </div>
                    </div>
                    {(errorMessage!=='') &&
                        <div className="msgError">{errorMessage}</div>
                    }
                </div>
                <div className="certificados">
                    <div className="openpay"><div className="logo">{t('transaction'??'',{ ns: namespaces.open_pay})}</div></div>
                    <div className="shield">{t('transaction'??'',{ ns: namespaces.open_pay})}</div>
                </div>
                <div className="contBTNPay">
                    <div className="" style={{pointerEvents:isDisabled?'none':'auto'}}  onClick={()=>OnsubmitFormOpenPay()}>
                        <a className="button rght" id="pay-button">{t('pay'??'',{ ns: namespaces.open_pay})}</a>
                    </div>
                </div>
                </form>
                </div>
            </div>
        </>
    );
}
