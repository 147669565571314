import * as React from 'react';
import {useEffect} from "react";
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {getStatusOpenPay} from "../../store/cart/cart";
import  {AxiosError} from "axios";
import {openNotifier} from '../../store/notifier/notifier';
import {openBackdrop} from '../../store/app/app';
import {clientSelector} from '../../store/clientInfo/clientInfo';
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {  useSearchParams } from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
export const ProcessingPayment=()=> {

    const { t } = useTranslation();
    let [searchParams] = useSearchParams();
    const ClientSelector = useSelector(clientSelector);
    let {
        client:{
        idPayment
        }
    } = ClientSelector;

    const dispatchApp = useAppDispatch();
                useEffect(() => {
                  dispatchApp(openBackdrop)
                    }, []);
            useEffect(() => {
                if (searchParams.get('id')) {
                    dispatchApp(getStatusOpenPay({id:searchParams.get('id')??''})).then(unwrapResult).then((result: any) => {
                         // @ts-ignore
                    dispatchApp(openNotifier({variant: "success", open: true, message: `an error occurred while trying to process payment`}))
                        window.location.href =result?.url
                }).catch((result: AxiosError) => {
                    // @ts-ignore
                    dispatchApp(openNotifier({variant: "error", open: true, message: `an error occurred while trying to process payment`}))
                    window.location.href =`https://www.playamiashoppingcart.com/error-payment/${searchParams.get('id')}`
                })

                    }

        }, []);
            return (
        <>
            <div className="contGracias">
                <h1>Payment is being processed...</h1>
                <p> <strong>Wait a moment </strong> while the payment status is confirmed </p>
            </div>
        </>
    );
}

