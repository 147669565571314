export const Speaker = require('./icon-speaker.png') as string;
export const Logo = require('./Logo.png') as string;
export const BB1 = require('./items/banner-day-pass.png') as string;
// IMAGENES DE LOS ITEMS
export const CGDP001 = require('./items/CGDP001.png') as string;
export const CGDP002 = require('./items/CGDP002.png') as string;
export const CGDP003 = require('./items/CGDP003.png') as string;
export const CGDSST010 = require('./items/CGDSST010.png') as string;
export const CGMCST008 = require('./items/CGMCST008.png') as string;
export const CGSKL007 = require('./items/CGSKL007.png') as string;
export const CGCCBBST011 = require('./items/CGCCBBST011.png') as string;
export const DAS = require('./items/dance-salsas.png') as string;
export const SNK = require('./items/cozumel-snorkel.png') as string;
// IMAGENES ADD-ONS
export const PMADS001 = require('./addons/PMADS001.jpg') as string;//fish spa
export const PMADS003 = require('./addons/PMADS003.jpg') as string;//snorkel tour
export const PMADS005 = require('./addons/PMADS005.jpg') as string;//wave runner

export const PMADS013 = require('./addons/PMADS013.jpg') as string;//CAMA LOUNGE


export const Wave = require('./waveRunner.jpg') as string;
// IMAGENES ADD-ONS TRANSPARENCIA
export const CDPMADS001 = require('./items/addons/CDPMADS001.png') as string;//fish spa
export const CDPMADS003 = require('./items/addons/CDPMADS003.png') as string;//snorkel tour
export const CDPMADS004 = require('./items/addons/PMADS004.jpg') as string;//snorkel gear
export const CDPMADS005 = require('./items/addons/CDPMADS005.png') as string;//wave runner 30 min
export const CDPMADS006 = require('./items/addons/CDPMADS005.png') as string;//wave runner 1 hr
export const CDPMADS007 = require('./items/addons/CDPMADS007.png') as string;//1 Print 6x8
export const CDPMADS008 = require('./items/addons/CDPMADS007.png') as string;//3 Print 6x8
export const CDPMADS009 = require('./items/addons/CDPMADS007.png') as string;//5 Print 6x8
export const CDPMADS010 = require('./items/addons/CDPMADS007.png') as string;//1 Digital Print 6x8
export const CDPMADS011 = require('./items/addons/CDPMADS007.png') as string;//3 Digital Print 6x8
export const CDPMADS012 = require('./items/addons/CDPMADS007.png') as string;//5 Digital Print 6x8
export const CDPMADS014 = require('./items/addons/CDPMADS014.png') as string;//camas lounge
export const CDPMADS015 = require('./items/addons/CDPMADS015.png') as string;//masaje con piedras
// IMAGENES ADD-ONS SIN TRANSPARENCIA
export const PMADS015 = require('./items/addons/PMADS015.jpg') as string;//hooby cat
export const PMADS014 = require('./items/addons/PMADS014.jpg') as string;//hooby cat
export const PMADS012 = require('./items/addons/PMADS012.jpg') as string;//Pedicure
export const PMADS011 = require('./items/addons/PMADS011.jpg') as string;//Manicure
export const PMADS004 = require('./items/addons/PMADS004.jpg') as string;//SNORKEL GEAR
export const PMADS016 = require('./items/addons/PMADS016.jpg') as string;//Banana boat
export const PMADS008 = require('./items/addons/PMADS008.jpg') as string;//Banana boat
// ICONOS
export const Calendar = require('./icons/calendar.png') as string;
export const Man = require('./icons/man.png') as string;
export const Boy = require('./icons/boy.png') as string;
export const Taxi = require('./icons/taxi.png') as string;
export const Clock = require('./icons/clock.png') as string;
export const Dinero = require('./dinero.png') as string;
export const Paypal = require('./paypal.png') as string;
export const ticketHeader = require('./ticket-en.jpg') as string;
export const ticketFooter = require('./ticketfooter.jpg') as string;
export const ParasailVertical = require('./parasailV.jpg') as string;
export const AddonTrue = require('./add-waverunners.jpg') as string;
///openPay js
export const Cards1 = require('./cards1.png') as string;
export const Cards2 = require('./cards2.png') as string;
export const Cvv = require('./cvv.png') as string;
export const OpenPay = require('./openPay.png') as string;
export const RadioOn = require('./radio_on.png') as string;
export const Security = require('./security.png') as string;
export const Error = require('./banner-error.jpg') as string;
