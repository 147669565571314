import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import {StepperComponent} from 'src/components/stepper/stepper';
import {ThankYou} from 'src/components/thankYou/thankYou';
import {ProcessingPayment} from 'src/components/processingPayment/processingPayment';
import {ErrorPayment} from 'src/components/errorPayment/errorPayment';
export const RoutesComponent = () => {
    return <BrowserRouter>
        <Routes>
            <Route
                path={'add-product'}
                element={<StepperComponent></StepperComponent>}
            >
                <Route
                    path={':id'}
                    element={<StepperComponent></StepperComponent>}
                >
                    <Route
                        path={':date'}
                        element={<StepperComponent></StepperComponent>}
                    >
                        <Route
                            path={':adult'}
                            element={<StepperComponent></StepperComponent>}
                        >
                            <Route
                                path={':children'}
                                element={<StepperComponent></StepperComponent>}
                            >
                                <Route
                                    path={':currency'}
                                    element={<StepperComponent></StepperComponent>}
                                >

                                    <Route
                                        path={':addon'}
                                        element={<StepperComponent></StepperComponent>}
                                    >

                                    </Route>
                                    <Route
                                        path={':origin_sale'}
                                        element={<StepperComponent></StepperComponent>}
                                    >

                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route
                path={'client-information'}
                element={<StepperComponent></StepperComponent>}
            >
                <Route
                    path={':folio'}
                    element={<StepperComponent></StepperComponent>}
                >
                </Route>
            </Route>
            <Route
                path={'addons'}
                element={<StepperComponent></StepperComponent>}
            >
            </Route>
            <Route
                path={'payment-method'}
                element={<StepperComponent></StepperComponent>}
            >
            </Route>
            <Route
                path={'final-book'}
                element={<StepperComponent></StepperComponent>}
            >
            </Route>
            <Route
                path={'thank-you/:folio'}
                element={<ThankYou></ThankYou>}
            >
            </Route>
            <Route
                path={'processing-payment'}
                element={<ProcessingPayment></ProcessingPayment>}
            >
            </Route>
            <Route
                path={'error-payment/:folio'}
                element={<ErrorPayment></ErrorPayment>}
            >
            </Route>
            <Route
                path={'/'}
                element={
                    <Navigate to={"add-product"}/>
                }>
            </Route>

        </Routes>
    </BrowserRouter>
}
