import * as React from 'react';
import './App.css';
import {Box} from '@mui/material';
import {RoutesComponent} from 'src/routes/routes'
import {Layout} from 'src/components/layout/layout';
import BackdropComponent from "./components/backdrop/backdrop";
import DialogComponent from "./components/dialog/dialog";
import {ModalAddon} from "./components/modalAddon/modalAddon"
import {ModalAddonTrue} from "./components/modalAddon/modalAddonTrue";
import {ErrorDate} from "./components/errorDate/errorDate"
import useNotifier from './components/notifier/notifier';
function App() {
    useNotifier();
    return (
    <Box sx={{ flexGrow: 1 }}>
        <BackdropComponent/>
        <DialogComponent></DialogComponent>
        <ErrorDate></ErrorDate>
        <ModalAddon></ModalAddon>
        <ModalAddonTrue></ModalAddonTrue>
        <Layout>
          <RoutesComponent></RoutesComponent>
        </Layout>
    </Box>
  );
}

export default App;
