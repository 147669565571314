import * as React from 'react';
import {Card, CardContent,Divider} from "@mui/material";
import {useEffect} from "react";
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {unwrapResult} from '@reduxjs/toolkit';
import {addItem, cartResumeSelector, cartSelector, resetCartItems} from "../../store/cart/cart";
import {AxiosError} from "axios";
import {openNotifier} from '../../store/notifier/notifier';
import {openBackdrop,closeBackdrop} from '../../store/app/app';
import {changeUnValidForm, clientSelector, getClientInfo, resetClient} from '../../store/clientInfo/clientInfo';
import {close_modal_addon} from "../../store/app/app";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {  useParams } from 'react-router-dom';

export const ThankYou=()=> {

    const { t } = useTranslation();
    let { folio } = useParams();
    const ClientSelector = useSelector(clientSelector);
    let {
        client:{name,
        last_name,
        email,
        phone_number,
        state_province,
        country,
        city,}
    } = ClientSelector;

    const dispatchApp = useAppDispatch();
                useEffect(() => {
                  dispatchApp(openBackdrop)
                    }, []);
            useEffect(() => {
                if (folio) {
                    dispatchApp(getClientInfo({folio:folio}))

                    dispatchApp((changeUnValidForm()));
                    dispatchApp((resetCartItems()));
                    dispatchApp((resetClient()));
                    window.sessionStorage.removeItem("persist:app");
                    window.sessionStorage.removeItem("persist:client");
                    window.sessionStorage.removeItem("persist:hotelCruiseLine");
                    window.sessionStorage.removeItem("persist:package");
                    window.sessionStorage.removeItem("persist:cart");
                    window.sessionStorage.clear();
                    }
        }, [folio]);
            return (
        <>
            <div className="contGracias">
                <h1>{t('thank'??'',{ ns: namespaces.thank_you})??''} {name} {last_name} {t('for_your_reservation'??'',{ ns: namespaces.thank_you})??''}</h1>
                <p>{t('few_moments'??'',{ ns: namespaces.thank_you})??''}<strong>{email}</strong> {t('ticket_folio'??'',{ ns: namespaces.thank_you})??''} <strong>{folio}</strong> </p>
            </div>
        </>
    );
}

