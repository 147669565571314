import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../rootReducer'
import {persistReducer} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
type variant = 'success' | 'warning' | 'info' | 'default' |'error';
interface app {
    backdrop: boolean,
    dialog:IDialog,
    notifier:INotifier,
    submit_cart:boolean,
    url_with_param:string,
    modal_addon:boolean,
    modal_addon_true:boolean
    origin_sale:string,
    error_modal:boolean,

}
interface INotifier {
    open: boolean
    variant: variant,
    message: string
}

interface IDialog{
    id:string,
    open:boolean,
    tittle:string,
    date:string
}
const initialState: app = {
    backdrop: false,
    dialog:{
        open:false,
        id:'',
        tittle:'',
        date:''
    },
    notifier:{
        open: false,
        variant: 'default',
        message: ''
    },
    submit_cart:false,
    modal_addon:false,
    modal_addon_true:false,
    error_modal:false,
    url_with_param:'',
    origin_sale:'',

};
const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        openBackdrop: (state) => {
            state.backdrop = true;
        },
        closeBackdrop: (state ) => {
            state.backdrop=initialState.backdrop
        },
        openDialog:(state,action:PayloadAction<IDialog>)=>{
            state.dialog.open=true
            state.dialog.id=action.payload.id
            state.dialog.tittle=action.payload.tittle
            state.dialog.date=action.payload.date
        },
        changeUrlFirst:(state,action:PayloadAction<string>)=>{
            state.url_with_param=action.payload
        },
        changeOriginSale:(state,action:PayloadAction<string>)=>{
            state.origin_sale=action.payload
        },
        closeDialog:(state)=>{
            state.dialog=initialState.dialog
        },
        cart_submit:(state)=>{
            state.submit_cart=true
        },
        open_modal_addon:(state)=>{
            state.modal_addon=true
        },
        close_modal_addon:(state)=>{
            state.modal_addon=false
        },
        open_modal_addon_true:(state)=>{
            state.modal_addon_true=true
        },
        close_modal_addon_true:(state)=>{
            state.modal_addon_true=false
        },
        open_modal_error:(state)=>{
            state.error_modal=true
        },
        close_modal_error:(state)=>{
            state.error_modal=false
        },
        not_cart_submit:(state)=>{
            state.submit_cart=initialState.submit_cart
        },
        openNotifier: (state, {payload}: PayloadAction<INotifier>) => {
            state.notifier.open = payload.open;
            state.notifier.variant=payload.variant;
            state.notifier.message=payload.message
        },
        closeNotifier: (state ) => {
            state.notifier.open=initialState.notifier.open
        },
    },
});

export const {openBackdrop,closeBackdrop,cart_submit,not_cart_submit,closeDialog,openDialog,changeUrlFirst,openNotifier,closeNotifier,open_modal_addon,close_modal_addon,open_modal_addon_true,close_modal_addon_true,changeOriginSale,close_modal_error,open_modal_error} = appSlice.actions

export const AppSelector = (state: RootState) => state.app
export const SubmitCartSelector = (state: RootState) => {
    return {
        submit_cart:state.app.submit_cart
    }
}
export const DialogSelector = (state: RootState) => {
    return {
        open_dialog:state.app.dialog.open,
        title_dialog:state.app.dialog.tittle,
        date_dialog:state.app.dialog.date,
        id_dialog:state.app.dialog.id
    }
}
export const ErrorDateSelector = (state: RootState) => {
    return {
        open_error_date:state.app.error_modal,
        origin_sale:state.app.origin_sale
    }
}
export const NotifierSelector = (state: RootState) => {
    return {
        open: state.app.notifier.open,
        variant: state.app.notifier.variant,
        message: state.app.notifier.message,
    }
}

export const AppValidateSelector = (state: RootState) => {
    return {
        validate_cart: state.cart.cart.items.length>0,
        validate_form: state.client.is_valid,
        validate_payment: false,
    }
}
export const AppReducer = persistReducer({
    storage: storageSession,
    key: 'app',
    whitelist: ['url_with_param'],
    blacklist:['backdrop','dialog','notifier','submit_cart']
}, appSlice.reducer)
