
export interface IAddons{
    sku: string
    tour: string,
    duration: string ,
    adult_price_usd:string ,
    child_price_usd: string ,
    idTransporte: string,
    adult_price_mxn: string,
    child_price_mxn: string
    pax:number
}
export interface IPackageState{
    addons: IAddons[],
    loading:boolean
    sku_addon:string
}

export const PackageIds = {
    'PMCZM001': 'PMCZM001-TF01',
    'PMCZM001-TF01': 'PMCZM001',
    'PMCZM003': 'PMCZM003-TF01',
    'PMCZM003-TF01': 'PMCZM003',
    'PMCZM004': 'PMCZM004-TF01',
    'PMCZM004-TF01': 'PMCZM004',
    'PMCZM006': 'PMCZM006-TF01',
    'PMCZM006-TF01': 'PMCZM006',
    'PMCZM008': 'PMCZM008-TF01',
};
