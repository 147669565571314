import { combineReducers } from '@reduxjs/toolkit';
import {ClientReducer} from './clientInfo/clientInfo'
import {CartReducer} from './cart/cart'
import {AppReducer} from './app/app'
import {HotelCruiseLineReducer} from './hotelCruiseLine/hotelCruiseLine'
import {PackageReducer} from './packages/package'
import {NotifierReducer} from './notifier/notifier'
export const rootReducer = combineReducers({
    cart:CartReducer,
    client:ClientReducer,
    hotelCruiseLine:HotelCruiseLineReducer,
    app:AppReducer,
    notifier:NotifierReducer,
    package:PackageReducer
})

export type RootState = ReturnType<any>

export default rootReducer

