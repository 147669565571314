import React,{useEffect} from 'react';
import {Logo} from "../../assets/images";
import {AppBar, Button, Container, Toolbar, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {useTranslation} from "react-i18next";

export const  Header=()=> {
    const { i18n } = useTranslation();

    return(
        <AppBar position="static" className="bg-menu">
            <Container maxWidth="xl">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon className="burgerMenu"/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    </Typography>
                    <img alt="Logo Playa Mia" src={Logo} className="logo"/>
                    {/*<Button variant="outlined" className="uk-button">Get Tickets</Button>*/}
                    <IconButton aria-label="delete">
                        <WhatsAppIcon className="iconWhatsapp"/>
                    </IconButton>
                    <Button className="esp" color="inherit"
                    onClick={(event)=>{
                        const lenguaje=i18n.resolvedLanguage;
                        i18n.changeLanguage(lenguaje==='es'?'en':(lenguaje==='en'?'es':'en')).then();
                    }}
                    >{i18n.resolvedLanguage==='es'?'ENG':'ESP'}</Button>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
