import React from 'react';
import {useSelector} from 'react-redux';
import {AppSelector} from "../../store/app/app";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropComponent = () => {
    const appSelector = useSelector(AppSelector);
    const {backdrop} = appSelector;
    return <Backdrop style={{zIndex: '9999'}}
                     open={backdrop}

    >
        <CircularProgress style={{color:'#fff'}} />
    </Backdrop>;
};

export default BackdropComponent;
