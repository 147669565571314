import * as React from 'react';
import {Card, CardContent,Divider} from "@mui/material";
import {
    Man,
    Taxi,
    Wave,
    PMADS001,
    PMADS003,
    PMADS005,
    PMADS011,
    PMADS013,
    PMADS015,
    PMADS008,
    PMADS004
} from "../../assets/images/index";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Button from "@mui/material/Button";
import GridViewIcon from '@mui/icons-material/GridView';
import Grid from "@mui/material/Unstable_Grid2";
import {listAddons,packageSelector,changePax} from "../../store/packages/package"
import {useEffect} from "react";
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {IAddons} from "../../models/package";
import {unwrapResult} from '@reduxjs/toolkit';
import {addItem, cartResumeSelector, cartSelector} from "../../store/cart/cart";
import {AxiosError} from "axios";
import {openNotifier} from '../../store/notifier/notifier';
import {clientSelector} from '../../store/clientInfo/clientInfo';
import {close_modal_addon, closeBackdrop} from "../../store/app/app";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
interface image_type {
    [key: string]: any;
}
const GetImage=({id_tour}: {id_tour: string})=> {
    const images_tour:image_type = {
        "PMADS001": <img src={PMADS001} alt="Fish SPA"/>,
        "PMADS003": <img src={PMADS003} alt="Snorkel Tour Xtabay"/>,
        "PMADS005": <img src={PMADS005} alt="Wave Runner 30 MIN"/>,
        "PMADS006": <img src={PMADS005} alt="Wave Runner 1 HR"/>,
        "PMADS011": <img src={PMADS011} alt="Manicure Basico"/>,
        "PMADS013": <img src={PMADS013} alt="Camas Lounge"/>,
        "PMADS015": <img src={PMADS015} alt="Hobie Cat 1 Hr"/>,
        "PMADS008": <img src={PMADS008} alt="Masaje Relajante 60 min"/>,
        "PMADS009": <img src={PMADS008} alt="Masaje Reflexologia 30 min"/>,
        "PMADS004": <img src={PMADS004} alt="Snorkel Gear "/>,
    };

    return images_tour[id_tour] ??   <img src={PMADS003} alt="Snorkel Tour Xtabay"/>;
}
const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 3,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};
export const AddOns=()=> {
    const dispatch = useAppDispatch();
    const PackageSelector = useSelector(packageSelector);
    let {
        addons,
        loading
    } = PackageSelector;
    useEffect(() => {
        if(addons.length<1){
            dispatch(listAddons({}))
        }

    }, []);
    const CartSelectorResume = useSelector(cartResumeSelector);
    const ClientSelector = useSelector(clientSelector);
    let {
        resume_currency,
    } = CartSelectorResume;
    const CartSelector = useSelector(cartSelector);
    let {
        cookie,
        cart: {
            items,
        }
    } = CartSelector;
    let {
        client:{
            dateOperation
        },
        folio
    } = ClientSelector;
    const { t } = useTranslation();
    return (
        <>
            <Grid md={12} display="flex" justifyContent="start" alignItems="flex-end">
                <div className="AshleySemBold txt-25">{t('recommended_products_to_increase_your_fun'??'',{ ns: namespaces.add_ons})}
                    {/*<Button style={{transform:'initial'}}  variant="outlined" className="btnCupon">Ver todo <GridViewIcon/></Button>*/}
                </div>
            </Grid>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {

                    addons.map((addon: IAddons,index:number)=>{
                        return <div className="mg-10" key={`fragment-addon-${index}`}>
                            <div className="contItem">

                                <div className="contImgItem">
                                    <GetImage id_tour={addon.sku}/>
                                    <span className="txtAddOns">{addon.tour}</span>
                                </div>
                                <div className="contInfoItem">
                                    <div>
                                        <div className="cont-flex">
                                            <div className="cont-txt-icon">
                                                <img src={Man} alt="icon man" className="iconItem"/>
                                                <span>
                                                    {
                                                        addon.sku === 'PMADS005' ||  addon.sku === 'PMADS006'
                                                            ? t('water_m' ?? '', { ns: namespaces.modal_addon })
                                                            :  addon.sku === 'PMADS013'
                                                                ? t('beds' ?? '', { ns: namespaces.modal_addon })
                                                                : t('adults' ?? '', { ns: namespaces.modal_addon })
                                                    } </span>
                                                <div> {addon.pax} </div>
                                            </div>
                                            <div className="btnMasMenos">
                                                <IconButton  onClick={()=>dispatch(changePax({pax:addon.pax>1?addon.pax-1:0,index:index}))} color="primary" aria-label="upload picture" component="label" className="btnCart">
                                                    <span className="menosIcon"><RemoveIcon/></span>
                                                </IconButton>
                                                <IconButton color="primary" disabled={addon.pax>=10} onClick={()=>dispatch(changePax({pax:addon.pax+1,index:index}))}  aria-label="upload picture" component="label" className="btnCart">
                                                    <span className="addIcon"><AddIcon/></span>
                                                </IconButton>
                                            </div>
                                        </div>

                                        {/*<div className="cont-grid">*/}
                                        {/*    <div className="cont-txt-icon">*/}
                                        {/*        <img src={Clock} alt="icon man" className="iconItem"/>*/}
                                        {/*        <span> Duración:</span>*/}
                                        {/*    </div>*/}
                                        {/*    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>*/}
                                        {/*        <Select*/}
                                        {/*            labelId="demo-simple-select-standard-label"*/}
                                        {/*            id="demo-simple-select-standard"*/}
                                        {/*            label="Age"*/}
                                        {/*            defaultValue={1}*/}
                                        {/*        >*/}
                                        {/*            <MenuItem value={1}>30 min</MenuItem>*/}
                                        {/*            <MenuItem value={2}>1 hr</MenuItem>*/}
                                        {/*        </Select>*/}
                                        {/*    </FormControl>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div>
                                        <div className="w-100 center mt-20">
                                            <Typography className="AshleyRegular">TOTAL</Typography>
                                            <Typography className="NunitoBold txt-30">${resume_currency==='USD'?addon.adult_price_usd.slice(0,addon.adult_price_usd.length - 4):addon.adult_price_mxn.slice(0,addon.adult_price_mxn.length - 4)}</Typography>
                                            <Typography className="NunitoBold txt-16">{resume_currency}</Typography>
                                        </div>
                                    </div>
                                    <div className="btnItemADS">
                                        <Button variant="outlined" className="btnCupon"
                                        disabled={addon.pax<=0}
                                        onClick={(event)=>{

                                            // dispatch(openDialog(true))
                                            dispatch(addItem({package:{
                                                    date_operation:folio!==''?dateOperation:items[0].date_operation,
                                                    adult:+addon.pax,
                                                    currency:resume_currency,
                                                    children:0,
                                                },

                                                currency:resume_currency,
                                                id:addon.sku??'',
                                                cookie:cookie})).then(unwrapResult).then((result: any) => {
                                                dispatch(changePax({pax:0,index:index}))
                                                dispatch(openNotifier({variant: "success", open: true, message: `${addon.tour} added successfully`}))
                                            }).catch((result: AxiosError) => {
                                                dispatch(closeBackdrop())
                                                // @ts-ignore
                                                dispatch(openNotifier({variant: "error", open: true, message: `an error occurred while trying to add it`}))
                                            })

                                        }}
                                        >{t('add'??'',{ ns: namespaces.add_ons})}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </Carousel>


        </>
    );
}

