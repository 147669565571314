import React, {useEffect, useRef, useState} from 'react';
import {Modal} from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {AddonTrue, Man, ParasailVertical} from "../../assets/images/index";
import InputLabel  from '@mui/material/InputLabel';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import {useSelector} from "react-redux";
import {
    AppSelector,
    changeUrlFirst,
    close_modal_addon_true,
    open_modal_addon_true
} from "../../store/app/app";
import {useAppDispatch} from "../../store/store";
import {changePax, listAddons, packageSelector,changeSkuAddon} from "../../store/packages/package";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {clientSelector, fillFolioDate, getClientInfo} from "../../store/clientInfo/clientInfo";
import {IAddons} from "../../models/package";
import {closeBackdrop, openBackdrop} from "../../store/app/app";
import {unwrapResult} from '@reduxjs/toolkit';
import {addItem, cartSelector, changeCurrency, cartResumeSelector, editItem} from "../../store/cart/cart";
import {openNotifier} from "../../store/notifier/notifier";
import {AxiosError} from "axios";
import {namespaces} from "../../i18n/i18n.constants";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Skeleton from '@mui/material/Skeleton';
import FormControl from "@mui/material/FormControl";
import {IHotelCruise} from "../../models/hotelCruiseLine";

import FormHelperText from "@mui/material/FormHelperText";
import {useTranslation} from "react-i18next";
export const ModalAddonTrue = () => {
    const appSelector = useSelector(AppSelector);

    const [adult_addon, setAdult_addon] = useState<number>(1);
    const dispatch = useAppDispatch();
    const {modal_addon_true} = appSelector;
    const PackageSelector = useSelector(packageSelector);
    const ClientSelector = useSelector(clientSelector);
    const CartSelectorResume = useSelector(cartResumeSelector);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
            },
        },
    };
    let {
        resume_currency,
    } = CartSelectorResume;

    let {
        folio,
        client:{
            name,
            last_name,
            dateOperation
        },
        loading,
        date_operation
    } = ClientSelector;

    let {
        addons,
        sku_addon
    } = PackageSelector;
    const CartSelector = useSelector(cartSelector);
    let {
        cookie,
    } = CartSelector;
    useEffect(() => {
        if(addons.length<1){
            dispatch(listAddons({}))
        }

    }, []);
    const [addon_sku, setAddon_sku] = useState(sku_addon?sku_addon:'');
    let skuAddonRef = useRef('');
    const { t } = useTranslation();

    let folioRef=useRef(folio);
    return        <Modal
        className="modalAddons true"
        open={modal_addon_true}
        disableAutoFocus
        disableEscapeKeyDown
        disableRestoreFocus
        disableEnforceFocus
        disableScrollLock
        // onClose={()=>dispatch(close_modal_addon())}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <div className="contModal">
            <div className="imgAddon">
                <img src={AddonTrue} alt="imgAddons"/>
            </div>
            <div className="formAddon">
                <Typography variant="h6" gutterBottom>{t('addon_rentals'??'',{ ns: namespaces.modal_addon})} </Typography>
                    <div>
                        <div>
                            <Typography variant="body1" gutterBottom>
                                {t('reservation_number'??'',{ ns: namespaces.modal_addon})}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {
                                    (loading && folio!=='') ? <Skeleton animation="wave" /> : folio
                                }

                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" gutterBottom>
                                {t('name'??'',{ ns: namespaces.modal_addon})}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {
                                    (loading && name!=='') ? <Skeleton animation="wave" /> : <>{name} {last_name}</>
                                }
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" gutterBottom>
                                {t('date_tour'??'',{ ns: namespaces.modal_addon})}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {
                                    (loading && dateOperation!=='') ? <Skeleton animation="wave" /> : dateOperation
                                }
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" gutterBottom>
                                {t('service'??'',{ ns: namespaces.modal_addon})}
                            </Typography>

                            <FormControl sx={{mt: 1, minWidth: '100%'}} variant="standard" >
                                {
                                    (loading || addons.length<0 ) ?
                                        <Box sx={{ width: '100%' }}>
                                            <LinearProgress />
                                        </Box> :
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="addon_id"
                                            value={sku_addon}
                                            MenuProps={MenuProps}
                                            onChange={(event, child)=>{
                                                setAddon_sku(event.target.value??'');
                                                dispatch(changeSkuAddon(event.target.value??''));
                                                skuAddonRef.current=event.target.value
                                            }}
                                        >

                                            {

                                                addons.map((addon:IAddons)=>
                                                    <MenuItem key={addon.sku} value={addon.sku}>
                                                        {addon.tour}
                                                    </MenuItem>)
                                            }

                                        </Select>

                                }
                            </FormControl>
                        </div>
                        <div>
                            <Typography variant="body1" gutterBottom>
                                {
                                    sku_addon === 'PMADS005' || sku_addon === 'PMADS006'
                                        ? t('water_m' ?? '', { ns: namespaces.modal_addon })
                                        : sku_addon === 'PMADS013'
                                            ? t('beds' ?? '', { ns: namespaces.modal_addon })
                                            : t('adults' ?? '', { ns: namespaces.modal_addon })
                                }
                            </Typography>
                            <div className="cont-grid pd-08">
                                <div className="cont-txt-icon">
                                    <img src={Man} alt="icon man" className="iconItem"/>
                                    <span> {adult_addon}</span>
                                </div>
                                <div className="btnMasMenos">
                                    <IconButton  color="primary" onClick={()=>adult_addon>1?setAdult_addon(adult_addon-1):setAdult_addon(1)} aria-label="upload picture" component="label" className="btnCart">
                                        <span className="menosIcon"><RemoveIcon/></span>
                                    </IconButton>
                                    <IconButton color="primary" disabled={adult_addon>=10} onClick={()=> setAdult_addon(adult_addon+1)} aria-label="upload picture" component="label" className="btnCart">
                                        <span className="addIcon"><AddIcon/></span>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style={{display:'flex',justifyContent:'center',alignItems:"end",width:'150px',margin:'0 auto'}}>
                        <Button
                            variant="outlined"
                            onClick={()=>{

                                if(folio!==''){
                                    dispatch(openBackdrop());
                                    dispatch(addItem({package:{
                                            date_operation:dateOperation,
                                            adult:adult_addon,
                                            currency:resume_currency,
                                            folio:folio,
                                            children:0,
                                        },
                                        currency:resume_currency,
                                        id:sku_addon,
                                        cookie:cookie})).then(unwrapResult).then((resultAdd: any) => {
                                        dispatch(closeBackdrop())
                                        dispatch(close_modal_addon_true())
                                        dispatch(openNotifier({variant: "success", open: true, message: `package is added successfully`}))
                                    }).catch((result: AxiosError) => {
                                        dispatch(closeBackdrop())
                                        // @ts-ignore
                                        dispatch(openNotifier({variant: "error", open: true, message: `an error occurred while trying to add it`}))
                                    })
                                }
                            }}
                            type="button"
                            className="btnCupon"
                            style={{width:'150px !important'}}
                        > {t('add'??'',{ ns: namespaces.modal_addon})}</Button>
                    </div>
            </div>
        </div>
    </Modal>;
};


